<template>
	<div class="text-center">
		<a
			class="inline-block p-2 mb-4 font-semibold text-white uppercase bg-green-500 rounded"
			:href="`tel:${cta.phone}`"
		>
			{{ cta.text }}
		</a>
		<div
			class="pb-4 m-auto leading-loose text-center"
			v-html="cta.hours"
		/>
		<div class="">
			Got a question relating to your Where’s Weed account, business account?
			<br>
			Visit our general
			<router-link
				to="/contact"
				class="font-bold text-green-500"
			>
				Help Center
			</router-link>
			for more help.
		</div>
	</div>
</template>

<script async>
export default {
	props: {
		cta: {
			type: Object,
			required: true
		}
	}
}
</script>
