export const LEAFWELL_COPY = {
	heroMessage: 'Once you have been approved by one of our partner’s medical marijuana doctors online, apply to the state medical marijuana program. Receive your MMJ card and start shopping for your medical cannabis from a licensed dispensary.',
	heroTitle: 'Get your Medical Cannabis Card in minutes',
	bullets: [
		'HIPAA Compliant - Privacy Guaranteed',
		'1 Year Physician’s Certificate',
		'No Appointment Necessary',
		'Instant PDF download'
	],
	features: [
		{
			image: require('@/assets/partners/leafwell1.svg'),
			text: 'Register and speak to a licensed physician*'
		},
		{
			image: require('@/assets/partners/leafwell2.svg'),
			text: 'Receive your doctor’s certificate*'
		},
		{
			image: require('@/assets/partners/leafwell3.svg'),
			text: 'Submit your application on your state website*'
		},
		{
			image: require('@/assets/partners/leafwell4.svg'),
			text: 'Get your certificate and start shopping*'
		}
	],
	banner: {
		title: 'Let’s get you approved for Medical Cannabis',
		message: 'See a doctor in minutes, only pay if approved.'
	}
}

export default LEAFWELL_COPY
