<template>
	<form
		class="max-w-2xl mx-auto mb-4"
		@submit.prevent="submitForm"
	>
		<div class="flex flex-wrap -mx-2 text-left">
			<div
				v-for="field in form"
				:key="field.id"
				class="flex flex-col w-full px-2 mb-3 md:w-1/2"
			>
				<WwTextInput
					v-if="field.type === TEL"
					:id="field.id"
					v-model="field.value"
					:input-type="TEL"
					input-class="w-full"
					:required="field.required"
					:value="field.value"
					:label="field.label"
					:autocomplete="field.autocomplete"
					:state="formPhoneInputState"
					@validity-changed="handlePhoneValidation"
				/>
				<WwTextInput
					v-else
					:id="field.id"
					v-model="field.value"
					:input-type="field.type"
					input-class="w-full"
					:required="field.required"
					:value="field.value"
					:label="field.label"
					:autocomplete="field.autocomplete"
					:state="inputFieldState(field.value, field.type)"
				/>
			</div>
		</div>
		<div class="flex justify-center w-full md:justify-end">
			<button
				type="submit"
				class="p-1 px-4 mt-2 text-lg font-semibold text-right text-white uppercase border-none rounded"
				:disabled="!formIsValid"
				:class="formIsValid ? 'bg-green-500' : 'bg-green-300 cursor-not-allowed'"
			>
				Submit
			</button>
		</div>
	</form>
</template>

<script async>
import { mapActions, mapMutations } from 'vuex'

import WwTextInput from '@/components/UI/WwTextInput.vue'
import INPUT_STATES from '@/constants/text-inputs/states.js'
import { EMAIL, TEL, TEXT } from '@/constants/text-inputs/types.js'
import { SUCCESS } from '@/constants/toast/type.js'
import { inputFieldState } from '@/validators/text-inputs.js'

export default {
	components: {
		WwTextInput
	},
	data () {
		return {
			form: [
				{ label: 'First Name', value: '', required: true, id: 'first_name', type: TEXT, autocomplete: 'given-name' },
				{ label: 'Last Name', value: '', required: true, id: 'last_name', type: TEXT, autocomplete: 'family-name' },
				{ label: 'Email', value: '', required: true, id: 'email', type: EMAIL, autocomplete: 'email' },
				{ label: 'Phone', value: '', required: true, id: 'phone', type: TEL, autocomplete: 'tel' },
				{ label: 'Company', value: '', required: true, id: 'company', type: TEXT, autocomplete: 'on' },
				{ label: 'Website', value: '', required: false, id: 'website', type: TEXT, autocomplete: 'url' }
			],
			TEL,
			INPUT_STATES,
			phoneValid: false,
			inputFieldState
		}
	},
	computed: {
		formPhoneInputState() {
			if (!this.form?.find(field => field.id === 'phone')?.value.length) {
				return INPUT_STATES.ERROR
			}
			return this.phoneValid ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		formIsValid() {
			return !this.form
				.filter(field => field.required)
				.some(field => inputFieldState(field.value, field.type) === INPUT_STATES.ERROR) &&
				this.formPhoneInputState === INPUT_STATES.SUCCESS
		},
		formData() {
			const obj = {}
			const assignField = (field) => { obj[field.id] = field.value }
			this.form.forEach(field => assignField(field))
			return obj
		}
	},
	methods: {
		...mapActions([ 'newPost' ]),
		...mapMutations('toast', [ 'showToast' ]),
		async submitForm() {
			const endpoint = 'Contact/postAdvertisingForm'
			const response = await this.newPost({
				endpoint,
				params: this.formData,
				store: this.$store
			})

			if (response.data?.success) {
				const clearValue = (field) => {
					field.value = ''
				}
				this.form.forEach(field => clearValue(field))
				this.success = true
				this.showToast({
					primaryText: 'Thanks for reaching out',
					type: SUCCESS
				})
			}
		},
		handlePhoneValidation(payload) {
			this.phoneValid = payload
		}
	}
}
</script>
