<template>
	<DefaultLayout>
		<div class="p-4 m-auto md:py-4">
			<div class="flex">
				<div class="flex-initial w-full pb-4 m-auto mb-4">
					<div class="pt-3 mb-8 text-center">
						<h1 class="mb-6 text-3xl font-bold">
							Tell us how we can help
						</h1>
						<p class="max-w-lg pb-4 m-auto mb-4 text-gray-500">
							Explore our frequently asked questions, learn more about cannabis consuption or reach out to list your business and much more!
						</p>
					</div>

					<div class="w-full mb-6 text-center uppercase">
						<h2 class="font-bold text-md">
							For Consumers
						</h2>
					</div>

					<div class="flex flex-wrap justify-center mb-12">
						<HubCard
							v-for="(card, index) in CONSUMER_CARDS"
							:key="`consumer-${card.link}-card-${index}`"
							:card="card"
						/>
					</div>

					<div class="w-full mb-6 text-center uppercase">
						<h2 class="font-bold text-md">
							For Businesses
						</h2>
					</div>

					<div class="flex flex-wrap justify-center mb-4">
						<HubCard
							v-for="(card, index) in BUSINESS_CARDS"
							:key="`business-${card.link}-card-${index}`"
							:card="card"
						/>
					</div>
				</div>
			</div>
		</div>
	</DefaultLayout>
</template>

<script async>
import HubCard from '@/components/multiUse/HubCard.vue'
import { BUSINESS_CARDS, CONSUMER_CARDS } from '@/constants/contactHub/hub-cards.js'
import { APP_META } from '@/constants/index.js'
import DefaultLayout from '@/layouts/default/Index.vue'

export default {
	components: {
		DefaultLayout,
		HubCard
	},
	data() {
		return {
			APP_META,
			meta: {
				title: `Contact ${APP_META.DOMAIN_NAME}`,
				description: `Contact ${APP_META.DOMAIN_NAME}`,
				keywords: '',
				canonical: '/contact'
			},
			CONSUMER_CARDS,
			BUSINESS_CARDS
		}
	},
	metaInfo: {
		title: `Contact Us - ${APP_META.DOMAIN_NAME}`,
		meta: [
			{ description: `Contact ${APP_META.NAME}` },
			{ canonical: '/contact' }
		]
	}
}
</script>
