<template>
	<div class="relative w-full">
		<div class="mb-2 text-2xl font-bold text-left uppercase">
			{{ heading }}
		</div>
		<div class="flex items-center mb-2 text-right justify-items-center">
			<div class="mb-2 text-left text-gray-500 w-60 md:w-auto">
				{{ subHeading }}
			</div>
			<router-link
				:to="url"
				class="my-auto ml-auto font-bold text-green-500"
			>
				View All
			</router-link>
		</div>
		<search-result-list
			:items="items"
			:is-mobile="false"
			:list-type="listType"
			:layout-type="INLINE"
		/>
	</div>
</template>

<script async>
import SearchResultList from '@/components/search/SearchResultList.vue'
import { INLINE } from '@/constants/search/layoutTypes.js'

export default {
	components: {
		SearchResultList
	},
	props: {
		items: {
			type: Array,
			required: true
		},
		listType: {
			type: String,
			required: true
		},
		url: {
			type: String,
			required: true
		},
		heading: {
			type: String,
			required: true
		},
		subHeading: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			INLINE
		}
	}
}
</script>
