<template>
	<DefaultLayout>
		<div>
			<LeafwellStateHeroBanner :state-name="stateName" />
			<div class="max-w-6xl p-3 m-auto space-y-8">
				<div
					class="py-2 text-xl leading-relaxed text-left"
					v-html="primaryCopy"
				/>
				<div class="max-w-xl p-4 mx-auto border border-gray-300 rounded-lg shadow">
					<h3 class="mb-2 text-2xl font-bold text-center md:px-8 text-leafwell-green">
						Let's get you approved for <p
							class="inline"
							:class="{'hidden': isMobile}"
						>
							your <br>
							{{ stateName }}
						</p> Medical Cannabis
					</h3>
					<div class="mb-2 text-lg font-bold text-center">
						See a doctor in minutes, only pay if approved.
					</div>
					<router-link
						to="/get-medical-marijuana-card"
						class="italic font-bold text-center hover:text-purple-500 text-leafwell-green"
					>
						(<span class="underline">
							Change your state
						</span>)
					</router-link>
					<LeafwellForm
						:state-name="stateName"
						class="pt-4"
					/>
				</div>
				<div
					v-if="pageData && pageData.content"
					class="py-2 text-left content"
					v-html="pageData.content"
				/>
				<WwFaq
					v-if="faqs && faqs.length"
					:heading="'FREQUENTLY ASKED QUESTIONS ABOUT MEDICAL MARIJUANA CARDS IN ' + stateName"
					:faq="faqs"
					class="px-3 py-3"
				/>
			</div>
		</div>
	</DefaultLayout>
</template>

<script async>
import { mapActions, mapGetters } from 'vuex'

import LeafwellForm from '@/components/leafwell/LeafwellForm.vue'
import LeafwellStateHeroBanner from '@/components/leafwell/LeafwellStateHeroBanner.vue'
import WwFaq from '@/components/UI/faq/WwFaq.vue'
import STATES from '@/constants/states.js'
import DefaultLayout from '@/layouts/default/Index.vue'

export default {
	components: {
		DefaultLayout,
		LeafwellForm,
		LeafwellStateHeroBanner,
		WwFaq
	},
	data() {
		return {
			pageData: {}
		}
	},
	computed: {
		faqs() {
			if (this.pageData?.faqs) {
				return JSON.parse(this.pageData.faqs)
			}
			return []
		},
		primaryCopy() {
			return this.pageData?.intro
		},
		stateName() {
			const urlArray = this.$route.path.split('/')

			return urlArray[urlArray.length - 1].split('-').map(word => {
				return word[0].toUpperCase() + word.slice(1)
			}).join(' ')
		},
		guideCategories() {
			return [
				`Is marijuana legal in ${this.stateName}?`,
				`Medical Marijuana in ${this.stateName}`,
				`Marijuana taxes in ${this.stateName}`
			]
		},
		statesList() {
			const list = []
			for (let i = 0; i < STATES.length; i++) {
				list.push(STATES[i].value)
			}
			return list
		},
		invalidState() {
			return !this.statesList.includes(this.$route.params.state)
		},
		...mapGetters([ 'isMobile' ])
	},
	mounted() {
		this.validateRoute()
	},
	methods: {
		...mapActions([ 'newGet', 'newPost' ]),
		validateRoute() {
			if (this.invalidState) {
				this.redirectToMainLeafwellPage()
			} else {
				this.fetchData()
			}
		},
		async fetchData() {
			const getData = {
				endpoint: 'Partners/getLeafwellStateDetails',
				params: { state: this.$route.params.state }
			}

			const response = await this.newGet(getData)
			this.pageData = response.data.results
		},
		redirectToMainLeafwellPage() {
			this.$router.push({ name: 'leafwellPrimary' })
		}
	}
}
</script>

<style lang="scss" scoped>
.text-leafwell-green {
	color: #01caaa;
}
.content :deep(h2) {
	@apply font-bold text-2xl py-4;
}
.content :deep(ul) {
	@apply list-disc pl-12;
}
.content :deep(li) {
	@apply leading-relaxed;
}
</style>
