export const GET_MED_CARD_FAQ = [
	{
		question: 'How do I get a medical card?',
		answer: '<p>The process differs from state to state. For most states, you have to first see a doctor and receive a doctor’s certificate which you use to apply to your state’s medical marijuana program. However, some states don’t require an application to their program. And others require steps before you see the doctor or require an in-person visit, rather than telehealth consultations offered by Leafwell.</p>'
	},
	{
		question: 'What benefits are there in having a Medical Marijuana Card and physician’s Cannabis Certificate or Recommendation?',
		answer:
		`<p class="mb-2">Benefits vary by state, but here are some of the most common potential benefits of a valid physician’s medical cannabis certificate and MMJ card:</p>
		\n
		<p>
			<ul class="ml-4 space-y-2">
				<li class="list-disc ml-2">In medical-only states, it’s the only way to legally consume cannabis.</li>
				\n
				<li class="list-disc ml-2">In states where recreational cannabis is legal, having a medical marijuana card can usually save money on taxes.</li>
				\n
				<li class="list-disc ml-2">MMJ Cards usually grant access to a wider range of products and dispensaries.</li>
				\n
				<li class="list-disc ml-2">Medical marijuana patients usually have larger possession limits than recreational users.</li>
				\n
				<li class="list-disc ml-2">Some states only allow medical marijuana patients are allowed to grow their own cannabis or allow them to grow a greater number of plants than recreational users.</li>
				\n
				<li class="list-disc ml-2">Some states’ medical marijuana cards can be used in other states (reciprocity), meaning that you can potentially gain access to another state’s medical marijuana program if visiting.</li>
			</ul>
		</p>`
	},
	{
		question: 'What documents do I need to show the Leafwell doctor during my online evaluation?',
		answer:
		`<p class="mb-2">Leafwell requires the following documents for your online evaluation:</p>
		\n
		<p>
			<ul class="ml-4 space-y-2 mb-2">
				<li class="list-disc ml-2">Photo ID (i.e., driver’s permit/license, state ID or U.S. passport)</li>
				\n
				<li class="list-disc ml-2">Proof of address (i.e., utility bill, mortgage, rental lease or bank statement)</li>
				\n
				<li class="list-disc ml-2">Medical records (i.e., patient history, doctor’s notes, prescription information/history, imaging/scan results)</li>
				\n
			</ul>
			<p>Physicians in states with more relaxed medical marijuana laws (like California) do not require you to upload medical records for your appointment.</p>
		</p>`
	},
	{
		question: 'What are my next steps after seeing the doctor?',
		answer:
		`<p class="mb-2">Leafwell requires the following documents for your online evaluation:</p>
		\n
		<p class="mb-2">After meeting with a Leafwell physician on their telehealth platform, you will be sent a certification email within a few minutes.</p>
		<p class="mb-2">In some states, a certification is all you need to shop directly for legal medical marijuana. If you live in one of these states, all you have to do is head to Where’s Weed to find your nearest medical dispensary and begin shopping.</p>
		<p>However, in most states, you’ll have to apply directly to the state’s medical marijuana program and upload the certification from Leafwell as proof that you’ve seen a doctor. Then the state will review your application and approve or deny your medical marijuana card. This card will be mailed to your home directly or can be accessed online for states that have e-cards.</p>
		`
	},
	{
		question: 'Does Leafwell work with medical marijuana doctors in my state?',
		answer: '<p>Yes, all of Leafwell’s doctors are licensed physicians within the state that they practice in. All of their physicians are experienced MMJ doctors who understand the value of cannabis as medicine and can help people interested in trying medical marijuana by providing medical support and guidance.</p>'
	},
	{
		question: 'Is my Medical Marijuana Card valid in other states?',
		answer:
		`<p class="mb-2">Some states accept out-of-state medical marijuana cards in a process called reciprocity. The following states accept out-of-state medical cards</p>
		\n
		<p>
			<ul class="ml-4 space-y-2 mb-4">
				<li class="list-disc ml-2">Alaska^</li>
				\n
				<li class="list-disc ml-2">Arkansas*</li>
				\n
				<li class="list-disc ml-2">California^</li>
				\n
				<li class="list-disc ml-2">Colorado^</li>
				\n
				<li class="list-disc ml-2">Hawaii*</li>
				\n
				<li class="list-disc ml-2">Maine</li>
				\n
				<li class="list-disc ml-2">Massachusetts</li>
				\n
				<li class="list-disc ml-2">Michigan</li>
				\n
				<li class="list-disc ml-2">Nevada</li>
				\n
				<li class="list-disc ml-2">Oklahoma*</li>
				\n
				<li class="list-disc ml-2">Oregon^</li>
				\n
				<li class="list-disc ml-2">Puerto Rico</li>
				\n
				<li class="list-disc ml-2">Washington^</li>
				\n
				<li class="list-disc ml-2">Washington, D.C.</li>
			</ul>
		</p>
		<i>
		<p class="mb-2">States marked with * require visitors to complete a visiting patient application for the duration of their stay.</p>
		<p>States marked with ^ do not allow out-of-state MMJ Cards but do allow recreational sales.</p>
		</i>
		`
	},
	{
		question: 'Does insurance cover my medical card?',
		answer:
		`<p class="mb-2">Because cannabis is federally illegal, medical insurance companies do not cover any of the costs associated with medical marijuana cards, including the doctor’s visit, card application fee or purchase of any medical marijuana products.</p>
		<p>Some states do offer discounted fees for people who are on state support such as Medicare or Social Security programs.</p>`
	},
	{
		question: 'What do you mean by HIPAA Compliance?',
		answer:
		`<p class="mb-2">HIPAA stands for “Health Insurance Portability and Accountability Act”, which was passed in the U.S. in 1996. Here’s a summary of what HIPAA does:</p>
		\n
		<p>
			<ul class="ml-4 space-y-2 mb-2">
				<li class="list-disc ml-2">Requires the protection and confidential handling of protected health information, including all medical and prescription records </li>
				\n
				<li class="list-disc ml-2">Gives you the right to transfer and continue health insurance coverage when you change or lose a job. </li>
				\n
				<li class="list-disc ml-2">Reduces health care fraud and abuse</li>
				\n
			</ul>
			<p>Need more information about HIPAA? <a href="https://www.dhcs.ca.gov/formsandpubs/laws/hipaa/Pages/1.00WhatisHIPAA.aspx">Click here</a>.</p>
		</p>`
	},
	{
		question: 'What are my employment rights as a medical cardholder?',
		answer:
		`<p class="mb-2">Every state has different laws when it comes to employment. Read these articles for more information:</p>
		\n
		<p>
			<ul class="ml-4 space-y-2 mb-2">
				<li class="list-disc ml-2"><a href="https://leafwell.co/blog/employment-rights-medical-marijuana-users/">Know Your Employment Rights As A Medical Marijuana User</a></li>
				\n
				<li class="list-disc ml-2"><a href="https://leafwell.co/blog/cannabis-laws-federal-workers/">Cannabis Laws Change For Federal Employees</a></li>
				\n
			</ul>
			<p>Please note that the Where’s Weed and Leafwell teams cannot provide you with any advice related to your legal or employment status.</p>
		</p>`
	},
	{
		question: 'When does my medical card expire?',
		answer:
		'<p>Your card will have an expiration date on it. If you received your card from Leafwell, they will also email you before the expiration date so you have plenty of time to renew it.</p>'
	},
	{
		question: 'How do I upload medical records?',
		answer:
		'<p>You can upload your medical records during the Leafwell application process. Please make sure all records include your full name. You can upload photos or scanned documents in PDF format during the process.</p>'
	},
	{
		question: 'What happens if I don\'t qualify?',
		answer:
		`<p class="mb-2">If you attend your physician’s appointment with Leafwell but do not qualify for a medical marijuana certificate, you will not be charged.</p>
		<p>Leafwell places a hold on your card before your appointment. If you are not approved, the hold is released and you are not charged. If your appointment is successful, they will charge you.</p>`
	},
	{
		question: 'Why hasn\'t my card arrived?',
		answer:
		`<p class="mb-2">In most states, after seeing a doctor you will have to apply for the state’s medical marijuana program.</p>
		<p class="mb-2">A few minutes after your appointment ends and you are officially approved, you will receive an email containing your medical marijuana certification letter. This will be in PDF format which allows you to upload digitally or print out and send in the mail (if your state doesn’t accept online applications). </p>
		<p>State processing and approval times may vary. Typically, patients should wait 1-2 weeks after applying to their state’s medical marijuana program as a minimum. If you’ve been waiting longer than a month, we recommend you contact the program directly. Neither Where’s Weed nor Leafwell can influence these times, as they only provide certifications needed for your state’s application.</p>`
	},
	{
		question: 'Does Leafwell offer refunds?',
		answer:
		`<p class="mb-2">Leafwell provides refunds on a case-by-case basis. If you believe you are entitled to a refund, please contact <a href="mailto:clinic@leafwell.co">clinic@leafwell.co</a> to request one.</p>
		<p class="mb-2">If Leafwell has not provided you with the high service standards that they seek to uphold, a refund can be requested.</p>
		<p>However, patients are not eligible for a refund if the online consultation was completed per our services and subsequent issues meant the patient was unable to complete the next steps of the process (i.e - being rejected by the state MMJ program for reasons beyond our control).</p>`
	},
	{
		question: 'What is Leafwell\'s refund policy?',
		answer:
		`<p class="mb-2">Leafwell is confident that you will be extremely satisfied with our services, but they understand that things do happen.</p>
		<p class="mb-2">In the unlikely event that your specific situation indicates that a refund may need to be issued to you, please email Leafwell support at <a href="mailto:support@leafwell.co">support@leafwell.co</a>.</p>
		<p class="mb-2">Leafwell handles refund requests on a case-by-case basis with the ultimate goal of providing you with the highest service standards, but they cannot offer refunds in every situation.</p>
		<p class="mb-2">Below are common situations that will not qualify you for a refund:</p>
		\n
		<p>
			<ul class="ml-4 space-y-2 mb-2">
				<li class="list-disc ml-2">You fail to complete state registration within the time scale required by the state or 120 days, whichever happens first.</li>
				\n
				<li class="list-disc ml-2">Mistakes or errors by you during the application process</li>
				\n
				<li class="list-disc ml-2">If services provided by Leafwell were completed, but you need your recommendation to be retracted or revoked. Leafwell can complete this process, but no refunds will be issued.</li>
				\n
				<li class="list-disc ml-2">Other circumstances, on a case-by-case basis.</li>
				\n
			</ul>
			<p>Please note that Leafwell only offers refunds onto the original payment method used for services fees.</p>
		</p>`
	}
]

export default GET_MED_CARD_FAQ
