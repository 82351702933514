<template>
	<!-- This calls for slots! -->
	<div class="hub-card-dimensions flex content-center mx-4 mb-4">
		<router-link
			v-if="!card.openExternal"
			:to="{ name: card.link }"
			class="text-black w-full flex-none border rounded-md shadow-md p-4 bg-white"
		>
			<div class="w-full flex justify-center pt-3">
				<img
					class="h-12 w-12"
					:src="requireIcon(card.image)"
					:alt="card.alt"
				>
			</div>
			<div class="w-full flex justify-center text-center pt-3">
				<h3 class="font-semibold p-2 text-base">
					<span v-html="card.title" />
				</h3>
			</div>
		</router-link>
		<a
			v-else
			:href="card.link"
			target="_blank"
			class="text-black w-full flex-none border rounded-md shadow-md p-4 bg-white"
		>
			<div class="w-full flex justify-center pt-3">
				<img
					class="h-12 w-12"
					:src="requireIcon(card.image)"
					:alt="card.alt"
				>
			</div>
			<div class="w-full flex justify-center text-center pt-3">
				<h3 class="font-semibold p-2 text-base">
					<span v-html="card.title" />
				</h3>
			</div>
		</a>
	</div>
</template>

<script async>
import requireIcon from '@/utils/requireIcon.js'

export default {
	props: {
		card: {
			type: Object,
			default: () => ({
				link: '',
				image: '',
				alt: '',
				title: ''
			})
		}
	},
	methods: {
		requireIcon
	}
}
</script>

<style lang="scss" scoped>
.hub-card-dimensions {
	width: 12.5rem;
	height: 13.125rem;
}
</style>
