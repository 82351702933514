<template>
	<DefaultLayout>
		<div class="max-w-6xl p-4 mx-auto text-left">
			<h1 class="pb-3 text-2xl font-semibold">
				PRIVACY POLICY
			</h1>

			<p class="pb-3 prose-lg">
				Effective Date: This Privacy Policy was last updated on 3/15/2021
			</p>

			<p class="pb-3 prose-lg">
				Your privacy is very important to us. This privacy policy ("<strong>
					Privacy Policy
				</strong>") describes how Viath, Inc  (together with our affiliates, the "<strong>
					Company
				</strong>", "<strong>
					we
				</strong>", "<strong>
					us
				</strong>" or "<strong>
					our
				</strong>") collects and uses the personal information you provide to us on our website: <router-link
					class="text-green-500"
					to="/"
				>
					https://{{ APP_META.DOMAIN }}
				</router-link> (together with any related mobile website and mobile application, "Site") and otherwise in connection with the services we provide through the Site and at our retail locations ("Services"). It also describes the choices available to you concerning our use of your personal information and how you can access and update this information.
			</p>

			<p class="pb-3 prose-lg">
				Use of our Site and Services is governed by, and subject to our <router-link to="/terms">
					Terms of Use
				</router-link>. This Privacy Policy is incorporated into our Terms of Use.  Please make sure you have carefully read and understand this Privacy Policy and our Terms of Use before using our Site or Services. By using our Site or Services, you agree to be bound by our Terms of Use and this Privacy Policy. <u>
					IF YOU DO NOT AGREE TO THIS PRIVACY POLICY AND OUR TERMS OF USE, YOU MAY NOT ACCESS OR OTHERWISE USE OUR SITE OR SERVICES.
				</u>
			</p>

			<p class="pb-4 prose-lg">
				We may modify this Privacy Policy from time to time, which we'll do by updating this online posting. Any updates will be effective upon posting unless otherwise noted. We encourage you to review this Privacy Policy periodically to ensure you have an up-to-date understanding of our privacy practices. By continuing to access or use the Site or Services after changes to this Privacy Policy become effective, you agree to be bound by the revised Privacy Policy. If any changes are unacceptable to you, you must stop using our Site and Services.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				SECTION 1 - INFORMATION WE COLLECT
			</h2>
			<h3 class="pb-3 prose-lg">
				Personal Information We May Collect:
			</h3>
			<p class="pb-3 prose-lg">
				We collect personal information when you use our Services, create an account with us or otherwise submit your personal information to us. Personal information may include your:
			</p>

			<ul class="pb-3 prose-lg">
				<li>
					name
				</li>
				<li>
					date of birth
				</li>
				<li>
					gender
				</li>
				<li>
					physical and/or billing address
				</li>
				<li>
					email
				</li>
				<li>
					phone number
				</li>
				<li>
					driver's license, state ID card or state medical marijuana ID card, including number and expiration
				</li>
				<li>
					Pre-Order history
				</li>
				<li>
					IP address, device identifiers and details of Site interactions
					username and login details
				</li>
				<li>
					Site and notification preferences
				</li>
				<li>
					payment information, such as card type, partial card number, expiration date, or CVV code
				</li>
				<li>
					survey responses and the content of any messages you may send us
					social media profile information such as user ID, screen name, display name, location, profile image and time zone
				</li>
			</ul>

			<p class="pb-3 prose-lg">
				To review and update your personal information to ensure it is accurate, contact us at [<a
					class="text-green-500"
					href="mailto:privacy@wheresweed.com"
				>
					privacy@wheresweed.com
				</a>].
			</p>

			<h3 class="pb-3 prose-lg">
				Non-Personal or Aggregate Information We May Collect:
			</h3>
			<p class="pb-3 prose-lg">
				When you access our Site or Services, we may automatically collect non-personally identifiable information from you, such as IP host address, approximate geolocation, web pages viewed, browser type, device type, operating system, referring pages, usage and browsing habits on the Site and similar data. We may also aggregate demographic information collected from our users (such as the number of users in a particular geographical location) in a manner which does not identify any particular individual. We may also aggregate information collected offline in connection with the Service, obtain non-personally identifiable information from third party sources and develop aggregate information by anonymizing previously collected personal information.
			</p>

			<p class="pb-4 prose-lg">
				We make reasonable efforts to prevent incidental collection of personal information, however it is possible at times when collecting non-personally identifiable information through automatic means that we may unintentionally collect or receive personal information that is mixed in with the non-personally identifiable information. If you believe that we have inadvertently collected your personal information, please notify us at <a
					class="text-green-500"
					href="mailto:privacy@wheresweed.com"
				>
					privacy@wheresweed.com
				</a>.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				SECTION 2 - HOW WE USE YOUR INFORMATION
			</h2>
			<p class="pb-3 prose-lg">
				We may use the personal information we collect about you to:
			</p>

			<ul class="pb-4 prose-lg">
				<li>
					provide Services that you request;
					communicate your Pre-Order requests to Dispensaries (as defined in our Terms of Use);
				</li>

				<li>
					assist our business partners in providing services to you at your request;
				</li>

				<li>
					respond to your requests and inquiries;
				</li>

				<li>
					communicate with you about our Services, promotions, advertisements, marketing materials and other information that may be of interest to you;
				</li>

				<li>
					set up and manage your account, including any reward or loyalty programs you opt into from time to time;
				</li>

				<li>
					authenticate your identity so you can access your account and use the Site;
				</li>

				<li>
					personalize your interactions with our Site and Services and our marketing communications;
				</li>

				<li>
					improve our Site, Services and promotional efforts;
				</li>

				<li>
					conduct research and analysis;
				</li>

				<li>
					prevent and identify fraud and administer our IT systems;
				</li>

				<li>
					comply with and enforce applicable legal requirements and policies such as this Privacy Policy and our Terms of Use; or
				</li>

				<li>
					accomplish any other purpose for which we provide you specific notice and obtain your consent, if required by applicable law.
				</li>
			</ul>

			<h2 class="pb-3 text-xl font-semibold">
				SECTION 3 - INFORMATION SHARING AND DISCLOSURE
			</h2>

			<p class="pb-3 prose-lg">
				We will only share your personal information with third parties as described in this Privacy Policy. We do not sell your personal information to third parties.
			</p>

			<p class="pb-3 prose-lg">
				We may share information related to your Pre-Order requests on the Site with the relevant Dispensaries. We may share your personal information with certain service providers (such as our payment processors or services that help us manage our communications to you) to assist us in providing, protecting, improving or promoting our Services or in connection with promotional or reward programs into which you have opted. These service providers are authorized to use your personal information only as necessary to provide their services to us.
			</p>

			<p class="pb-3 prose-lg">
				We may also disclose your personal information:
			</p>

			<ul class="pb-3 prose-lg">
				<li>
					as required by law or to comply with a subpoena or similar legal process;
				</li>

				<li>
					when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud or harassment, or respond to a government request;
				</li>

				<li>
					to any affiliate in our corporate family as us as long as its privacy practices are substantially similar to ours;
				</li>

				<li>
					in the event we sell, transfer or change control of all or a portion of our business or assets, including by merger, acquisition, joint venture, reorganization, divestiture, dissolution or liquidation; or
				</li>

				<li>
					to any other third party with your prior consent to do so.
				</li>
			</ul>

			<p class="pb-3 prose-lg">
				Non-personally identifiable or aggregate information may be used by us for any purposes permitted by law and may be shared with third parties, provided such information does not specifically identify you.
			</p>

			<p class="pb-4 prose-lg">
				Our Site may contain links to third party websites, products and services. These third-party websites, products, and services are independent from us and the information they collect is governed by their privacy practices and not subject to this Privacy Policy. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				SECTION 4 - COOKIES AND SIMILAR TECHNOLOGIES
			</h2>

			<p class="pb-3 prose-lg">
				Cookies are small data files stored on your browser or device. Our Site may use cookies (and similar technologies such as clear gifs, web beacons, tags, etc.) to customize your visit and for other purposes to make your visit more convenient or to enable us to enhance our Services. We may also use and place cookies (and similar technologies) on your computer from our third-party service providers in connection with your use of the Site to help us manage and analyze the Site.
			</p>

			<p class="pb-3 prose-lg">
				For example, we may use cookies on our site for Google Analytics or similar web-based analytics tools that help us understand how visitors engage with the Site and improve it. These analytics tools collect information anonymously and report website trends without identifying individual visitors. You can opt out of Google Analytics without affecting how you visit our Site. For more information on opting out of being tracked by Google Analytics across all websites you use, visit https://tools.google.com/dlpage/gaoptout.
			</p>

			<p class="pb-3 prose-lg">
				We may also use Google conversion tracking and/or similar services to help us understand your and other users' use of the Site.
			</p>

			<p class="pb-3 prose-lg">
				Additionally, our advertisers and business partners may set cookies and similar items on your computer when you use our Site.
			</p>

			<p class="pb-4 prose-lg">
				You may stop or restrict the placement of cookies on your computer or flush them from your browser by adjusting your web browser preferences. This will not prevent you from using the Site but may interfere with some of its functionality.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				SECTION 5 - RIGHTS AND CHOICES
			</h2>

			<p class="pb-3 prose-lg">
				You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in our emails or contacting us at <a
					class="text-green-500"
					href="mailto:privacy@wheresweed.com"
				>
					privacy@wheresweed.com
				</a>.
			</p>

			<p class="pb-4 prose-lg">
				Subject to applicable law, you may have the right to access the personal information we maintain about you; request that we change personal information about you that is not accurate; and request that we delete certain personal information about you. Where our collection and use of your personal information depends upon your consent, you have the right to withdraw your consent at any time.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				SECTION 6 - CALIFORNIA RESIDENTS: YOUR CALIFORNIA PRIVACY RIGHTS
			</h2>

			<p class="pb-3 prose-lg">
				Under the California Consumer Privacy Act of 2018 ("CCPA"), California residents have certain rights regarding the collection, use, and sharing of their personal information. We do not sell your personal information and will not do so in the future without providing you with notice and an opportunity to opt-out of such sale as required by law.
			</p>

			<p class="pb-3 prose-lg">
				If you reside in California, you or your authorized agent may request that we:
			</p>

			<ul class="pb-3 prose-lg">
				<li>
					disclose to you the categories of personal data we have collected, used or disclosed within the last 12 months, including personal data (if any) that we disclosed to third parties for direct marketing purposes;
				</li>

				<li>
					delete your personal data; and/or
				</li>

				<li>
					transfer to you an electronic machine-readable copy of specific pieces of your personal data we have collected or used within the last 12 months.
				</li>
			</ul>

			<p class="pb-3 prose-lg">
				To exercise these rights, please contact us at <a
					class="text-green-500"
					href="mailto:privacy@wheresweed.com"
				>
					privacy@wheresweed.com
				</a>. We may ask you for certain information, require email verification or take other steps to verify your identity. Applicable law may require or permit us to decline your request and we will notify you if this is the case. We will process your request within a reasonable time after receipt. We will not discriminate against you based upon your exercise of any of these rights.
			</p>

			<p class="pb-4 prose-lg">
				Separate from the CCPA, California's Shine the Light law gives California residents the right to ask companies what personal information they share with third parties for those third parties' direct marketing purposes. We do not disclose your personal information to third parties for the purpose of directly marketing their goods or services to you unless you request such disclosure. If you have any questions regarding this policy, or would like to change your preferences, you may contact us at <a
					class="text-green-500"
					href="mailto:privacy@wheresweed.com"
				>
					privacy@wheresweed.com
				</a>.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				SECTION 7 - NEVADA RESIDENTS: YOUR NEVADA PRIVACY RIGHTS
			</h2>

			<p class="pb-4 prose-lg">
				Nevada law (SB 220) requires website operators to provide a way for Nevada consumers to opt out of the sale of certain information that the website operator may collect about them. We do not sell your personal information to third parties as defined in Nevada law, and will not do so in the future without providing you with notice and an opportunity to opt-out of such sale as required by law. If you have any questions regarding our data privacy practices or our compliance with Nevada data privacy law, please contact us at <a
					class="text-green-500"
					href="mailto:privacy@wheresweed.com"
				>
					privacy@wheresweed.com
				</a>.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				SECTION 8 – SECURITY
			</h2>
			<p class="pb-4 prose-lg">
				The security of your personal information is very important to us. We maintain reasonable physical, electronic, and managerial safeguards and follow generally accepted industry standards to protect against the accidental, unauthorized or unlawful destruction, loss, alteration, access, disclosure or use of the personal information submitted to us, both during transmission and once we receive it. Only those employees who need access to your information in order to perform their duties are authorized to have access to your personally identifiable information. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure and we cannot guarantee that your personal information will be completely safe from unauthorized access by third parties such as hackers. Your use of our Site demonstrates your assumption of this risk. If you have any questions about security on our Site, you can contact us at <a href="mailto:privacy@wheresweed.com">
					privacy@wheresweed.com
				</a>.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				SECTION 9 – CHILDREN AND PRIVACY
			</h2>
			<p class="pb-3 prose-lg">
				This Site and our Services are designed for a general audience and are not directed to children. We do not knowingly collect or solicit personal information from children under the age of 13 (or other relevant age, as applicable pursuant to relevant law) through the Site. If we become aware that we have collected personal information from a child under such age, we will promptly use all reasonable efforts to delete such information from our records. If you believe that a child under such age may have provided us with personal information, please contact us at <a
					class="text-green-500"
					href="mailto:privacy@wheresweed.com"
				>
					privacy@wheresweed.com
				</a>.
			</p>

			<p class="pb-4 prose-lg">
				By using this site, you represent that you are at least the Minimum Age of 21 as set out in our Terms of Use. If you are do not meet the Minimum Age requirement, you must not use this Site or our Services.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				SECTION 10 – YOUR COMMUNICATIONS WITH US
			</h2>
			<p class="pb-3 prose-lg">
				By providing your contact information (including, without limitation, your email address, physical address, and phone number) (collectively the "<strong>
					Channels
				</strong>") to us, you expressly consent to receive communications from us.  We may use the Channels to communicate with you, send information you have requested or send information about other products or services developed or provided by us or our business partners, provided that, we will not give your contact information to another party to promote their products or services directly to you without your consent or as set forth in this Privacy Policy.
			</p>

			<p class="pb-3 prose-lg">
				By providing your phone number to us, you expressly consent to receive phone calls and/or text messages from us. We will not give your phone number to another party to promote their products or services directly to you without your consent or as set forth in this Privacy Policy. Any phone calls and/or text messages delivered to your phone or device may cause you to incur extra data, text messaging, or other charges from your wireless carrier. MESSAGE AND DATA RATES MAY APPLY. You are solely responsible for any carrier charges incurred as a result of phone and/or text communications from us.
			</p>

			<p class="pb-4 prose-lg">
				Any communication or material you transmit to us by email or otherwise, including any data, questions, comments, suggestions, or similar, will be treated as, non-confidential and nonproprietary information.  Except to the extent expressly covered by this Privacy Policy, anything you transmit or post may be used by us for any purpose, including but not limited to, reproduction, disclosure, transmission, publication, broadcast and posting. Furthermore, you expressly agree that we are free to use any ideas, concepts, know-how, or techniques contained in any communication you send to us, as well as any data developed using the content of such communication, without compensation and for any purpose whatsoever, including but not limited to, developing, manufacturing and marketing products and services using such information
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				SECTION 11 – NO RIGHTS OF THIRD PARTIES:
			</h2>
			<p class="pb-4 prose-lg">
				This Privacy Policy does not create rights enforceable by third parties, nor does it require disclosure of any personal information relating to users of the Site or our Services.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				SECTION 12 – DATA TRANSFERS
			</h2>
			<p class="pb-4 prose-lg">
				Our servers are maintained in the United States.  By using the Site and Services, you freely and specifically give us your consent to export your personal information to the United States and to store and use it in the United States as specified in this Privacy Policy.  You understand that data stored in the United States may be subject to lawful requests by the courts or law enforcement authorities in the United States. At this time, we do not ship products outside the United States.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				SECTION 13 – GOVERNING LAW
			</h2>
			<p class="pb-4 prose-lg">
				The interpretation, application and effect of this Privacy Policy shall be governed by the laws of the State of Colorado without regard to conflict of law principles. By using our Site or Services, you expressly consent and submit to the jurisdiction and venue of the state court in the City and County of Denver, Colorado for any claims or legal actions arising out of or in connection with the Site or Services.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				SECTION 14 - QUESTIONS AND CONTACT INFORMATION
			</h2>
			<p class="pb-3 prose-lg">
				If you would like to access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information, please contact us at <a
					class="text-green-500"
					href="mailto:privacy@wheresweed.com"
				>
					privacy@wheresweed.com
				</a>.
			</p>
		</div>
	</DefaultLayout>
</template>

<script async>
import { APP_META } from '@/constants/index.js'
import DefaultLayout from '@/layouts/default/Index.vue'

export default {
	components: {
		DefaultLayout
	},
	data() {
		return {
			APP_META,
			name: '',
			company: '',
			phone: '',
			email: '',
			department: '',
			message: '',
			meta: {
				title: `${APP_META.DOMAIN_NAME} Privacy Policy`,
				description: ' ',
				keywords: '',
				canonical: '/privacy'
			}
		}
	},
	created: function() {
		this.$store.commit('setMeta', this.meta)
	},
	methods: {
		onClick() {
			// Close the menu and (by passing true) return focus to the toggle button
			this.$refs.ddown.hide(true)
		}
	}
}
</script>
