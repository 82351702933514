<template>
	<div
		class="py-12 bg-green-500 bg-cover lg:bg-center"
		:style="{ backgroundImage: `url(${leafwellBannerImage})`}"
	>
		<div class="flex flex-1 max-w-6xl m-auto">
			<div class="relative flex flex-wrap justify-center w-full px-3 text-white md:justify-between">
				<div class="flex flex-col items-center lg:items-start lg:justify-center">
					<h1 class="mb-4 text-4xl font-bold">
						<img
							src="@/assets/partners/leafwell-logo-white.png"
							width="256"
							height="36"
							alt="leafwell logo"
						>
					</h1>
					<div class="max-w-sm mb-8 text-2xl font-bold tracking-wide text-center lg:max-w-none lg:text-left lg:mb-0">
						{{ subtitle }}
					</div>
				</div>
				<div
					:style="{height: '173px', width: '360px'}"
					class="-mb-12"
				/>
				<div
					class="absolute bottom-0 left-0 right-0 mx-auto -mb-12 md:left-auto"
					:style="{height: '173px', width: '336px'}"
				>
					<WwImage
						:src="handWithCardImage"
						classes="rounded-t-xl"
						object-cover
						alt="Hand holding marijuana card"
						:fallback-image="lazyHandWithCardImage"
						:width="220"
						:height="220"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script async>

export default {
	components: {
		WwImage: () => import('@/components/multiUse/WwImage.vue')

	},
	props: {
		stateName: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			leafwellBannerImage: require('@/assets/leafwell/banner-bg.jpg'),
			handWithCardImage: require('@/assets/leafwell/hand-card.png'),
			lazyHandWithCardImage: require('@/assets/leafwell/hand-card-small.png')
		}
	},
	computed: {
		subtitle() { return `How to get your ${this.stateName} medical marijuana card` }
	}
}
</script>
