<template>
	<DefaultLayout>
		<div class="container py-4 about-wrap">
			<div class="flex flex-wrap py-4">
				<div class="w-full py-4 lg:w-1/2 about-text">
					<p>
						<strong>
							Where's Weed
						</strong> is the comprehensive online consumer resource that connects visitors with relevant cannabis businesses across the US and Canada. We showcase a variety of different directories to help people find dispensaries, delivery services, MMJ doctors, smoke shops, branded products and related deals -- plus relevant cannabis news and culture.
					</p>

					<p>
						At our core, we're a <strong>
							technology company
						</strong> with a passionate team who truly cares about the consumer. Since 2011, we've led with education to allow visitors to safely experience the legalized cannabis industry, while providing a platform where canna-businesses can advertise, legally.
					</p>

					<p>
						Whether you're looking for a new favorite dispensary, want to order products online or read the latest cannabis news & culture, we've got your back!
					</p>

					<h3>
						Questions?
					</h3>
					<a
						href="/contact"
						class="px-4 py-1 rounded-full text-purple-500 border border-solid border-purple-500 font-bold my-5 py-2 hover:bg-purple-500 hover:text-white"
					>
						Contact Us
					</a>
				</div>
				<div class="w-full py-2 lg:w-1/2 about-img">
					<img
						src="/img/about.jpg"
						alt="About"
					>
				</div>
			</div>
		</div>
	</DefaultLayout>
</template>

<script async>
import DefaultLayout from '@/layouts/default/Index.vue'

export default {
	components: {
		DefaultLayout
	},
	metaInfo: {
		title: 'About Us - Wheresweed.com',
		meta: [
			{ description: 'Where\'s Weed is the comprehensive online consumer resource that connects visitors with relevant cannabis businesses across the US and Canada. We showcase a variety of different directories to help people find dispensaries, delivery services, MMJ doctors, smoke shops, branded products and related deals -- plus relevant cannabis news and culture.' },
			{ canonical: '/about' }
		]
	}
}
</script>
