import { EMAIL, TEXT } from '@/constants/contactHub/data-types.js'
import { PHONE_INPUT, TEXT_AREA, TEXT_INPUT } from '@/constants/contactHub/input-types.js'

export const FORM_NAME = 'contact_sales_form'

export const CONTACT_SALES_FORM = [
	{
		name: 'firstName',
		label: 'First Name',
		placeholder: 'First Name',
		inputType: TEXT_INPUT,
		dataType: TEXT,
		required: true
	},
	{
		name: 'lastName',
		label: 'Last Name',
		placeholder: 'Last Name',
		inputType: TEXT_INPUT,
		dataType: TEXT,
		required: true
	},
	{
		name: 'email',
		label: 'Email',
		placeholder: 'your@email.com',
		inputType: TEXT_INPUT,
		dataType: EMAIL,
		required: true
	},
	{
		name: 'phone',
		label: 'Phone Number',
		placeholder: '(555) 555-5555',
		inputType: PHONE_INPUT,
		dataType: TEXT,
		required: false
	},
	{
		name: 'company',
		label: 'Business Name',
		placeholder: 'Business Name',
		inputType: TEXT_INPUT,
		dataType: TEXT,
		required: true
	},
	{
		name: 'website',
		label: 'Website',
		placeholder: 'Website',
		inputType: TEXT_INPUT,
		dataType: TEXT,
		required: false
	},
	{
		name: 'message',
		label: 'Message',
		placeholder: 'Write your message...',
		inputType: TEXT_AREA,
		dataType: TEXT,
		required: true
	}
]
