<template>
	<div class="w-full py-4">
		<WwCarousel
			id="advocacy"
			:item-count="partners.length"
			:items-per-slide="4"
			item-spacing="space-x-4"
			side-scroller-class="px-4 xl:px-0"
			scrollbar-visibility="xl:hide-horizontal-scrollbar"
			arrow-visibility="hidden xl:block"
			hide-footer
		>
			<template #header>
				<h2 class="text-lg text-center text-gray-500 uppercase font-bold pb-2">
					Our Featured Partners
				</h2>
			</template>
			<template #slides>
				<PartnerCard
					v-for="(item, index) in partners"
					:key="`partner-logo-${index}`"
					:item="item"
					class="w-1/4 hover:bg-gray-200 hover:shadow rounded-xl xl:w-52"
				/>
			</template>
		</WwCarousel>

		<div class="p-4 text-lg text-left md:text-center">
			{{ message }}
		</div>
	</div>
</template>

<script async>
import WwCarousel from '@/components/UI/WwCarousel.vue'
import PartnerCard from '@/views/advocacy/components/PartnerCard.vue'

export default {
	components: {
		WwCarousel,
		PartnerCard
	},
	props: {
		partners: {
			type: Array,
			required: true
		},
		message: {
			type: String,
			required: true
		}
	}
}
</script>
