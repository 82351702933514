<template>
	<form @submit.prevent="submitForm">
		<div class="flex flex-wrap -mx-2">
			<div
				v-for="field in formFields"
				:key="field.label"
				class="w-full md:w-1/2"
			>
				<div class="mx-2 text-left">
					<WwTextInput
						v-if="field.type === TEL"
						:id="field.label"
						v-model="field.value"
						:input-type="field.type"
						input-class="w-full"
						:required="true"
						:value="field.value"
						:state="formPhoneInputState"
						:label="field.label"
						@validity-changed="handlePhoneValidation"
					/>
					<WwTextInput
						v-else
						:id="field.label"
						v-model="field.value"
						input-class="w-full"
						:input-type="field.type"
						:label="field.label"
						:required="true"
						:value="field.value"
						:state="inputFieldState(field.value, field.type)"
					/>
				</div>
			</div>

			<div class="flex flex-col w-full px-2 pt-4 pb-2 text-left">
				<div class="flex items-center pb-2 text-sm cursor-pointer">
					<input
						v-model="form.email_allowed"
						aria-label="Signup for Emails"
						type="checkbox"
						class="border-gray-300 rounded text-leafwell-green hover:bg-gray-200 focus:ring focus:ring-green-300 focus:border-gray-300"
					>
					<span class="pl-2">
						Yes! I want to receive promotional emails from Where's Weed.
					</span>
				</div>

				<div class="flex items-center mb-6 text-sm cursor-pointer">
					<input
						v-model="form.agree"
						aria-label="Agree to Terms"
						type="checkbox"
						required
						class="border-gray-300 rounded text-leafwell-green hover:bg-gray-200 focus:ring focus:ring-green-300 focus:border-gray-300"
					>
					<span class="pl-2">
						I have read and agree to the Where's Weed <router-link to="/terms">
							terms of service
						</router-link> and <router-link to="/privacy">
							privacy
						</router-link>.
					</span>
				</div>

				<button
					type="submit"
					class="self-center px-6 py-2 text-lg font-semibold text-white uppercase border-none rounded"
					:disabled="!formIsValid"
					:class="formIsValid ? 'bg-leafwell-green' : 'bg-gray-300 cursor-none'"
				>
					Let's Get Started!
				</button>
			</div>
		</div>
	</form>
</template>

<script async>
import { mapActions, mapMutations } from 'vuex'

import WwTextInput from '@/components/UI/WwTextInput.vue'
import INPUT_STATES from '@/constants/text-inputs/states.js'
import { EMAIL, NUMBER, TEL, TEXT } from '@/constants/text-inputs/types.js'
import { SUCCESS } from '@/constants/toast/type.js'
import { inputFieldState } from '@/validators/text-inputs.js'

const initialFormData = {
	firstName: { label: 'First Name', value: '', type: TEXT },
	lastName: { label: 'Last Name', value: '', type: TEXT },
	email: { label: 'Email', value: '', type: EMAIL },
	zip: { label: 'Zip', value: '', type: NUMBER },
	phone: { label: 'Phone', value: '', type: TEL },
	email_allowed: true,
	text_allowed: true,
	agree: false
}

export default {
	components: {
		WwTextInput
	},
	props: {
		stateName: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			form: initialFormData,
			leafwellUrl: 'https://wheresweed.leafwell.co?utm_source=wheresweed&utm_medium=leafwell_landing&utm_campaign_affiliate=form',
			successMessage: 'Your medical marijuana card application is underway! Please continue to Leafwell to complete your application.',
			TEL,
			EMAIL,
			TEXT,
			NUMBER,
			INPUT_STATES,
			phoneValid: false,
			inputFieldState
		}
	},
	computed: {
		formFields() {
			return [ 'firstName', 'lastName', 'email', 'zip', 'phone' ]
				.map(field => initialFormData[field])
		},
		formIsValid() {
			const {
				firstName, lastName, email, zip, phone, agree
			} = this.form
			return firstName.value.length &&
				lastName.value.length &&
				phone.value.length &&
				email.value.length &&
				zip.value.length &&
				agree &&
				this.formPhoneInputState === INPUT_STATES.SUCCESS
		},
		urlWithParams() {
			const {
				phone, email
			} = this.form

			return `${this.leafwellUrl}&email=${email.value}&phone=${phone.value}&state=${this.stateName}`
		},
		formPhoneInputState() {
			if (!this.form?.phone?.value?.length) {
				return INPUT_STATES.ERROR
			}
			return this.phoneValid ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		}
	},
	methods: {
		...mapActions([ 'newPost' ]),
		...mapMutations('toast', [ 'showToast' ]),
		async submitForm() {
			const endpoint = 'Partners/postLeafwellForm'
			const {
				firstName,
				lastName,
				email,
				phone,
				zip,
				// eslint-disable-next-line camelcase
				text_allowed,
				// eslint-disable-next-line camelcase
				email_allowed
			} = this.form
			const formData = {
				first_name: firstName.value,
				last_name: lastName.value,
				phone: phone.value,
				state: this.stateName,
				zip: zip.value,
				email: email.value,
				text_allowed,
				email_allowed
			}

			const response = await this.newPost({
				endpoint,
				params: formData,
				store: this.$store
			})
			if (response?.data?.success) {
				this.form = initialFormData
				this.success = true
				this.showToast({
					primaryText: this.successMessage,
					type: SUCCESS
				})

				setTimeout(() => {
					window.open(this.urlWithParams, '_blank')
				}, 3000)
			}
		},
		handlePhoneValidation(payload) {
			this.phoneValid = payload
		},
		fieldState(value) {
			return value?.length ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		}
	}
}
</script>

<style scoped>
.bg-leafwell-green {
	background: #01caaa;
}
.text-leafwell-green {
	color: #01caaa;
}
</style>
