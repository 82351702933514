export const CONSUMER_CARDS = [
	{
		link: 'https://consumers.wheresweed.com',
		openExternal: true,
		image: 'faq-consumers.svg',
		alt: 'Consumer FAQ Icon',
		title: 'FAQ for <br>Consumers'
	},
	{
		link: 'leaf411',
		image: 'cannabis-leaf.svg',
		alt: 'Cannabis Consumption Icon',
		title: 'Cannabis Consumption 101'
	},
	{
		link: 'support',
		image: 'help-wheel.svg',
		alt: 'Consumer Support Icon',
		title: 'Get in touch <br>with Support'
	}
]

export const BUSINESS_CARDS = [
	{
		link: 'https://business.wheresweed.com',
		openExternal: true,
		image: 'faq-businesses.svg',
		alt: 'Business FAQ Icon',
		title: 'FAQ for <br>Businesses'
	},
	{
		link: 'sales',
		image: 'headphone-support.svg',
		alt: 'Sales Support Icon',
		title: 'Get in touch <br>with Sales'
	}
]
