<template>
	<div class="w-full text-left">
		<h2 class="hidden xl:block mb-2 text-lg font-bold text-center uppercase md:text-2xl md:text-left">
			{{ heading }}
		</h2>
		<div :class="[contentClasses]">
			<h2 class="pt-4 text-lg xl:hidden font-bold text-center uppercase md:text-2xl md:text-left">
				{{ heading }}
			</h2>
			<FaqList
				:faqs="faq"
				:columns="columns"
				:class="faqClasses"
			>
				<template #default>
					<slot name="button" />
				</template>
			</FaqList>
			<slot name="after" />
		</div>
	</div>
</template>

<script async>
import FaqList from '@/components/UI/faq/FaqList.vue'

export default {
	components: {
		FaqList
	},
	props: {
		heading: {
			type: String,
			default: ''
		},
		faq: {
			type: Array,
			required: true
		},
		columns: {
			type: Number,
			default: 1
		},
		contentClasses: {
			type: [ Array, String ],
			default: ''
		},
		faqClasses: {
			type: String,
			default: ''
		}
	}
}
</script>
