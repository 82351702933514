import { render, staticRenderFns } from "./LeafwellForm.vue?vue&type=template&id=4501dab2&scoped=true&"
import script from "./LeafwellForm.vue?vue&type=script&async=true&lang=js&"
export * from "./LeafwellForm.vue?vue&type=script&async=true&lang=js&"
import style0 from "./LeafwellForm.vue?vue&type=style&index=0&id=4501dab2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4501dab2",
  null
  
)

export default component.exports