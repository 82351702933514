<template>
	<a
		:ref="CAROUSEL_ITEM"
		:href="item.link"

		target="_blank"
		class="flex flex-none h-full items-center"
	>
		<img
			:src="item.imgSrc"
			:alt="item.name"
			class="w-full"
		>
	</a>
</template>

<script async>
import CAROUSEL_ITEM from '@/constants/carousel/carousel-item.js'

export default {
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			CAROUSEL_ITEM
		}
	}
}
</script>
