<template>
	<div class="pb-4">
		<h2 class="text-lg text-center text-gray-500 uppercase">
			Latest Advocacy Articles
		</h2>
		<div class="grid grid-cols-2 mt-2 -mx-2 md:grid-cols-4">
			<BlogCard
				v-for="article in articles"
				:key="article.id"
				:article="article"
				class="mx-2 mb-4 text-left border border-gray-300 shadow-md hover:text-green-500 rounded-xl"
				:image-height="230"
				:image-width="320"
				text-class="h-32 px-3 mb-2 title truncate-overflow"
				card-class="rounded-t-xl"
			/>
		</div>
	</div>
</template>

<script async>
import BlogCard from '@/components/blog/Card.vue'

export default {
	components: {
		BlogCard
	},
	props: {
		articles: {
			type: Array,
			required: true
		}
	}
}
</script>

<style lang="scss" scoped>
	.title {
		--lh: 2rem;
		line-height: var(--lh);
	}
	.truncate-overflow {
		--max-lines: 2;
		max-height: calc(var(--lh) * var(--max-lines));
		overflow: hidden;
	}

</style>
