<template>
	<div />
</template>

<script async>
export default {
	props: {
		routeFrom: {
			type: String,
			default: ''
		}
	},
	mounted: function () {
		this.$store.dispatch('userLogOut')
		this.$router.push(this.routeFrom)
	}
}
</script>
