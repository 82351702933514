<template>
	<default-layout>
		<div class="max-w-6xl px-4 mx-auto">
			<div class="flex flex-wrap items-center justify-between text-left">
				<div class="order-2 w-full md:w-1/2 md:order-1">
					<h2 class="pb-3 text-3xl leading-10">
						Looking to increase reach & drive more sales? <span class="font-bold">
							We've got you covered.
						</span>
					</h2>
					<p class="py-3">
						Where’s Weed offers a variety of engaging digital advertising opportunities to help drive brand awareness, traffic & online orders. We strive to organically insert your client’s brand and messaging into the conversation and will work with your team to ensure these efforts fit your specific marketing goals. Request our media kit below to find out more!
					</p>
				</div>
				<div
					class="flex justify-center order-1 w-full my-4 md:w-1/2 md:order-2"
					:style="{ height: '300px'}"
				>
					<img
						:src="require('@/assets/illustrations/wheresadweed.svg')"
						width="300"
						height="300"
						alt="Connect with Wheresweed"
					>
				</div>
			</div>
			<advertising-contact-form />
		</div>
	</default-layout>
</template>

<script async>
import AdvertisingContactForm from '@/components/advertising/AdvertisingContactForm.vue'
import DefaultLayout from '@/layouts/default/Index.vue'

export default {
	components: {
		AdvertisingContactForm,
		DefaultLayout
	},
	metaInfo: {
		title: 'Where\'s Weed Digital Advertising Opportunities | Feature Your Business on Where\'s Weed',
		meta: [ {
			name: 'description',
			content: 'Want to drive brand awareness, traffic & online orders, utilize the unique digital advertising opportunities from Where\'s Weed'
		} ]
	}
}
</script>
