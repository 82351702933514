export const STATES = [
	{ name: 'Alabama', value: 'alabama' },
	{ name: 'Alaska', value: 'alaska' },
	{ name: 'Arizona', value: 'arizona' },
	{ name: 'Arkansas', value: 'arkansas' },
	{ name: 'California', value: 'california' },
	{ name: 'Colorado', value: 'colorado' },
	{ name: 'Connecticut', value: 'connecticut' },
	{ name: 'Delaware', value: 'delaware' },
	{ name: 'Florida', value: 'florida' },
	{ name: 'Georgia', value: 'georgia' },
	{ name: 'Hawaii', value: 'hawaii' },
	{ name: 'Idaho', value: 'idaho' },
	{ name: 'Illinois', value: 'illinois' },
	{ name: 'Indiana', value: 'indiana' },
	{ name: 'Iowa', value: 'iowa' },
	{ name: 'Kansas', value: 'kansas' },
	{ name: 'Kentucky', value: 'kentucky' },
	{ name: 'Louisiana', value: 'louisiana' },
	{ name: 'Maine', value: 'maine' },
	{ name: 'Maryland', value: 'maryland' },
	{ name: 'Massachusetts', value: 'massachusetts' },
	{ name: 'Michigan', value: 'michigan' },
	{ name: 'Minnesota', value: 'minnesota' },
	{ name: 'Mississippi', value: 'mississippi' },
	{ name: 'Missouri', value: 'missouri' },
	{ name: 'Montana', value: 'montana' },
	{ name: 'Nebraska', value: 'nebraska' },
	{ name: 'Nevada', value: 'nevada' },
	{ name: 'New Hampshire', value: 'new-hampshire' },
	{ name: 'New Jersey', value: 'new-jersey' },
	{ name: 'New Mexico', value: 'new-mexico' },
	{ name: 'New York', value: 'new-york' },
	{ name: 'North Carolina', value: 'north-carolina' },
	{ name: 'North Dakota', value: 'north-dakota' },
	{ name: 'Ohio', value: 'ohio' },
	{ name: 'Oklahoma', value: 'oklahoma' },
	{ name: 'Oregon', value: 'oregon' },
	{ name: 'Pennsylvania', value: 'pennsylvania' },
	{ name: 'Puerto Rico', value: 'puerto-rico' },
	{ name: 'Rhode Island', value: 'rhode-island' },
	{ name: 'South Carolina', value: 'south-carolina' },
	{ name: 'South Dakota', value: 'south-dakota' },
	{ name: 'Tennessee', value: 'tennessee' },
	{ name: 'Texas', value: 'texas' },
	{ name: 'Utah', value: 'utah' },
	{ name: 'Vermont', value: 'vermont' },
	{ name: 'Virginia', value: 'virginia' },
	{ name: 'Washington', value: 'washington' },
	{ name: 'West Virginia', value: 'west-virginia' },
	{ name: 'Wisconsin', value: 'wisconsin' },
	{ name: 'Wyoming', value: 'wyoming' }
]

export default STATES
