<template>
	<div
		class="grid w-full grid-cols-1 gap-4 pt-4"
		:class="`md:grid-cols-${columns}`"
	>
		<slot />
		<div
			v-for="(formattedFaq, index) in formattedFaqs"
			:key="formattedFaq.question"
		>
			<div class="relative h-full">
				<div
					:id="`faq-${index}`"
					class="absolute top-0 -mt-16"
				/>
				<FaqListItem
					:faq="formattedFaq"
					:faq-index="index"
				/>
			</div>
		</div>
	</div>
</template>

<script async>
import FaqListItem from '@/components/UI/faq/FaqListItem.vue'

export default {
	components: {
		FaqListItem
	},
	props: {
		faqs: {
			type: Array,
			required: true
		},
		columns: {
			type: Number,
			default: 1
		}
	},
	computed: {
		formattedFaqs() {
			if (this.faqs.some(property => !property.question)) {
				return this.faqs.map(({ q, a, button }) => ({
					question: q,
					answer: a,
					button
				}))
			}
			return this.faqs
		}
	}
}
</script>
