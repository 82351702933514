<template>
	<DefaultLayout>
		<div class="max-w-6xl mx-auto">
			<HeroSection
				:message="copy.heroMessage"
				class="px-4"
				@scroll-to-newsletter="scrollMeTo('footer')"
			/>

			<FeaturedPartners
				:partners="partners"
				:message="copy.featuredPartners"
				class="xl:px-4"
			/>

			<FeaturedBlogCards
				:blog-cards="blogCards"
				:is-mobile="isMobile"
				class="px-4"
			/>
			<ArticleCards
				:articles="articles"
				:is-mobile="isMobile"
				class="px-4"
			/>

			<h2 class="w-full px-4 pb-4 text-lg font-bold text-center text-gray-500 uppercase md:pb-8">
				Continue the Conversation Online
			</h2>
			<div
				class="px-4 pb-16 embedsocial-hashtag"
				data-ref="30eaea502ee6ca980953a0f5eecfd27f43643d43"
			/>
		</div>
	</DefaultLayout>
</template>

<script async>
import { mapGetters, mapMutations } from 'vuex'

import destructureBlogArticles from '@/api/helpers/destructureBlogArticles.js'
import apiService from '@/api/index.js'
import { ERROR } from '@/constants/toast/type.js'
import DefaultLayout from '@/layouts/default/Index.vue'
import { logError } from '@/utils/error-handling.js'
import ArticleCards from '@/views/advocacy/components/ArticleCards.vue'
import FeaturedBlogCards from '@/views/advocacy/components/FeaturedBlogCards.vue'
import FeaturedPartners from '@/views/advocacy/components/FeaturedPartners.vue'
import HeroSection from '@/views/advocacy/components/HeroSection.vue'

export default {
	components: {
		DefaultLayout,
		HeroSection,
		FeaturedPartners,
		FeaturedBlogCards,
		ArticleCards
	},
	data () {
		return {
			partners: [
				{
					name: 'Anti-Defamation League',
					imgSrc: require('@/assets/partners/advocacy/ADL.png'),
					link: 'https://www.adl.org/'
				},
				{
					name: 'Bail Project',
					imgSrc: require('@/assets/partners/advocacy/BailProject.png'),
					link: 'https://bailproject.org/'
				},
				{
					name: 'BIPOCANN',
					imgSrc: require('@/assets/partners/advocacy/BipoCann.png'),
					link: 'https://bipocann.com/'
				},
				{
					name: 'Cannabis Impact Fund',
					imgSrc: require('@/assets/partners/advocacy/CIF.png'),
					link: 'https://cannabisimpactfund.org/'
				},
				{
					name: 'Color Of Change',
					imgSrc: require('@/assets/partners/advocacy/ColorOfChange.png'),
					link: 'https://colorofchange.org/'
				},
				{
					name: 'Doctors for Cannabis Regulation',
					imgSrc: require('@/assets/partners/advocacy/DCR.png'),
					link: 'https://www.dfcr.org/'
				},
				{
					name: 'Drug Policy Alliance',
					imgSrc: require('@/assets/partners/advocacy/DrugPolicyAlliance.png'),
					link: 'https://drugpolicy.org/'
				},
				{
					name: 'Human Rights Campaign',
					imgSrc: require('@/assets/partners/advocacy/HumanRightsCampaign.png'),
					link: 'https://www.hrc.org/'
				},
				{
					name: 'Last Prisoner Project',
					imgSrc: require('@/assets/partners/advocacy/LastPrisonerProject.png'),
					link: 'https://www.lastprisonerproject.org/'
				},
				{
					name: 'Marijuana Policy Project',
					imgSrc: require('@/assets/partners/advocacy/MPP.png'),
					link: 'https://www.mpp.org/'
				},
				{
					name: 'NORML',
					imgSrc: require('@/assets/partners/advocacy/Norml.png'),
					link: 'https://norml.org/'
				},
				{
					name: 'One Colorado',
					imgSrc: require('@/assets/partners/advocacy/OneColorado.png'),
					link: 'https://one-colorado.org/'
				},
				{
					name: 'PACE',
					imgSrc: require('@/assets/partners/advocacy/PaceIndy.png'),
					link: 'https://www.paceindy.org/'
				},
				{
					name: 'Planned Parenthood',
					imgSrc: require('@/assets/partners/advocacy/PlannedParenthood.png'),
					link: 'https://www.plannedparenthood.org/'
				},
				{
					name: 'Southern Poverty Law Center',
					imgSrc: require('@/assets/partners/advocacy/SPLC.png'),
					link: 'https://www.splcenter.org/'
				},
				{
					name: 'Trevor Project',
					imgSrc: require('@/assets/partners/advocacy/TrevorProject.png'),
					link: 'https://www.thetrevorproject.org/'
				}
			],
			blogCards: [
				{
					title: 'Incarceration to Liberation:<br>Evelyn LaChapelle\'s Cannabis Redemption Story',
					content: 'Imagine going to jail for a harmless favor you did for a friend - just...',
					contentClass: 'mb-auto md:mb-auto px-4',
					image: {
						name: 'Image of Evelyn LaChapelle',
						imgSrc: require('@/assets/partners/evelyn-lachapelle.jpg')
					},
					buttons: [
						{
							text: 'Continue Reading',
							link: '/blog/category/2021/apr/wrongful-incarceration-to-liberation-evelyn-lachapelles-cannabis-redemption-story',
							color: 'bg-green-500'
						}
					]
				},
				{
					title: 'The fight for fairness starts with cannabis policy reform',
					titleClass: 'pt-2 mt-auto md:pt-4',
					content: 'Find out more about each are of interest by clicking the links below:',
					buttons: [
						{
							text: 'Medical Cannabis',
							link: '/blog/health',
							color: 'bg-purple-500'
						},
						{
							text: 'Legalization',
							link: '/blog/legalization',
							color: 'bg-purple-500'
						},
						{
							text: 'Industry-Specific',
							link: '/blog/industry',
							color: 'bg-purple-500'
						},
						{
							text: 'Cannabis News',
							link: '/blog/news',
							color: 'bg-purple-500'
						}
					]
				},
				{
					content:
						`
						<a href="blog/category/2021/feb/a-conversation-with-ernest-toney-founder-of-bipocann" class="text-black">
							<div class="relative z-50 p-4 text-lg leading-7">
								“The more I listened, the
								more pronounced the
								cultural awareness and
								opportunity gaps for BIPOC
								communities in the cannabis
								landscape seemed and it
								was frustrating."
								<br>
								<br>
								<div class="pt-4 text-base font-bold">Ernest Toney</div>
								<div class="text-base md:text-sm lg:text-base">BIPOCANN Founder</div>
							</div>
						</a>

						<img class="absolute bottom-0 right-0 rounded-br-xl" src="${require('@/assets/partners/ernest-toney.jpg')}"/>
						`,
					contentClass: 'text-xl'
				}
			],
			copy: {
				heroMessage: 'Where\'s Weed seeks to make the cannabis industry more equitable through education and our partnerships.',
				featuredPartners: 'We believe that advocacy is a group effort, so we\'ve teamed up with other leaders in the space including BIPOCANN, with a goal of creating a more accessible and profitable legal cannabis industry for BIPOC business owners and professionals.  By working with partners like Last Prisoner Project, we’re hoping to redress the past and continuing harms of the War on Drugs. We see that the cannabis industry is a work in progress to reach an equitable landscape for everyone, and hope that we can do our part to help shed light on these issues in the future.',
				newsletter: 'Subscribe to our newsletter for the latest news & cannabis culture straight to your inbox!'
			},
			articles: []
		}
	},
	metaInfo: {
		title: 'Where\'s Weed Advocacy | Making the Cannabis Industry More Equitable Through Education and Partnerships',
		meta: [ {
			name: 'description',
			content: 'Where\'s Weed seeks to make the cannabis industry more equitable through education and our partnerships.'
		}
		]
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		postData () {
			return {
				type: 'Blog',
				func_name: 'getCategoryPageData',
				data: {
					category: 'advocacy'
				}
			}
		}
	},
	mounted() {
		this.fetchArticles()
		this.fetchInstagramFeed(document, 'script', 'EmbedSocialHashtagScript')
	},
	methods: {
		...mapMutations('toast', [ 'showToast' ]),
		scrollMeTo(id) {
			try {
				const element = document.getElementById(id)
				window.scrollTo({
					left: 0,
					top: element.offsetTop,
					behavior: 'smooth'
				})
			} catch (e) {
				logError(e)
			}
		},
		async fetchArticles() {
			try {
				const response = await apiService.get(this.postData)
				if (response.data.errors) {
					throw response.data.errors[0].message
				}
				this.articles = destructureBlogArticles(response.data.results.blogs).slice(0, 4)
			} catch (e) {
				this.showToast({
					primaryText: 'Error',
					secondaryText: e,
					type: ERROR
				})
				logError(e)
			}
		},
		fetchInstagramFeed(d, s, id) {
			const js = d.createElement(s)
			js.id = id
			js.src = 'https://embedsocial.com/cdn/ht.js'
			d.getElementsByTagName('head')[0].appendChild(js)
		}
	}
}
</script>
