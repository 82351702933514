<template>
	<div class="flex flex-wrap items-center">
		<img
			class="w-full md:w-1/2 h-80"
			src="@/assets/partners/advocacy-hero.svg"
			alt="Advocacy Hero"
		>

		<div class="w-full text-left md:w-1/2 md:order-first">
			<p class="max-w-md text-2xl font-bold">
				{{ message }}
			</p>
			<button
				v-if="!isMobile"
				class="w-full p-2 my-6 font-bold text-center text-white uppercase bg-green-500 border-none rounded shadow-sm md:w-auto"
				@click="$emit('scroll-to-newsletter')"
			>
				Subscribe to our newsletter
			</button>
		</div>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

export default {
	props: {
		message: {
			type: String,
			required: true
		}
	},
	emits: [ 'scroll-to-newsletter' ],
	computed: {
		...mapGetters([ 'isMobile' ])
	}
}
</script>
