<template>
	<div class="h-full group">
		<div
			class="w-full p-3 bg-gray-200 rounded shadow-sm cursor-pointer group-hover:shadow"
			:class="{ 'h-full': !isExpanded }"
			@click="isExpanded = !isExpanded"
		>
			<div class="flex font-bold flex-nowrap">
				<span class="pr-4 text-sm">
					{{ faq.question }}
				</span>
				<div class="ml-auto text-4xl font-bold text-gray-500">
					<PlusIcon
						v-show="!isExpanded"
						class="h-6"
					/>
					<MinusIcon
						v-show="isExpanded"
						class="h-6"
					/>
				</div>
			</div>
			<transition name="fade-and-slide">
				<WwHtmlContent
					v-if="isExpanded"
					class="w-full mt-3 text-xs"
					:markup="faq.answer"
				/>
			</transition>
		</div>
	</div>
</template>

<script async>
import MinusIcon from '@/components/icons/MinusIcon.vue'
import PlusIcon from '@/components/icons/PlusIcon.vue'

export default {
	components: {
		WwHtmlContent: () => import('@/components/UI/WwHtmlContent.vue'),
		PlusIcon,
		MinusIcon
	},
	props: {
		faq: {
			type: Object,
			required: true
		},
		faqIndex: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			isExpanded: this.$route.fullPath.includes(`faq-${this.faqIndex}`)
		}
	},
	computed: {
		url() {
			return this.$route.fullPath
		}
	},
	watch: {
		url() {
			this.isExpanded = this.$route.fullPath.includes(`faq-${this.faqIndex}`)
		}
	}
}
</script>
