<template>
	<DefaultLayout>
		<div class="w-full mx-auto">
			<section class="relative mx-auto bg-white">
				<div class="absolute bottom-0 left-0 right-0 text-[#f8f9fb]">
					<AngledBackground />
				</div>
				<div class="relative max-w-6xl px-4 mx-auto">
					<HeroSection
						:message="LEAFWELL_COPY.heroMessage"
						:title="LEAFWELL_COPY.heroTitle"
						:bullets="LEAFWELL_COPY.bullets"
						:states="STATES"
					/>
				</div>
			</section>
			<section class="px-4 bg-[#f8f9fb]">
				<div class="grid max-w-6xl grid-cols-2 pb-4 mx-auto md:grid-cols-4">
					<div
						v-for="(item, index) in LEAFWELL_COPY.features"
						:key="index"
					>
						<img
							:src="item.image"
							class="mx-auto"
							:alt="item.text"
							:height="138"
							:width="138"
						>
						<h3 class="mx-auto text-sm text-center md:text-base md:w-48">
							{{ item.text }}
						</h3>
					</div>
				</div>
				<div class="w-full max-w-6xl py-3 mx-auto text-sm italic text-right md:text-base">
					*Process will vary by state
				</div>
			</section>
			<section class="h-64 mx-auto text-black bg-[#CCB7F1]">
				<div class="flex flex-wrap content-center justify-center h-full px-4 space-y-3 text-center">
					<h3 class="w-full text-2xl font-bold">
						Let’s get you approved for Medical Cannabis
					</h3>
					<p class="w-full pb-2 text-lg">
						See a doctor in minutes, only pay if approved.
					</p>
					<select
						v-model="selected"
						aria-label="Select State"
						class="p-2 text-lg font-semibold bg-transparent border-2 border-black rounded appearance-none w-72 md:w-60 focus:ring focus:ring-purple-500 focus:border-white"
					>
						<option
							disabled
							selected
							:value="null"
							class="text-black"
						>
							Select your state:
						</option>
						<option
							v-for="state in STATES"
							:key="state.value"
							:value="state.value"
							class="text-black"
						>
							{{ state.name }}
						</option>
					</select>
				</div>
			</section>
			<section class="max-w-6xl pt-3 mx-auto">
				<WwFaq
					heading="FREQUENTLY ASKED QUESTIONS ABOUT MEDICAL MARIJUANA CARDS"
					:faq="GET_MED_CARD_FAQ"
					:columns="2"
					class="px-3 py-3"
				/>
			</section>
			<section class="max-w-6xl px-3 mx-auto md:px-0">
				<SearchResultRow
					v-if="blogArticles"
					class="w-full py-3"
					:items="blogArticles"
					:list-type="BLOGS_LIST"
					heading="LATEST MEDICAL MARIJUANA ARTICLES"
					sub-heading="Stay up to date with the cannabis industry"
					url="/blog"
				/>
			</section>
		</div>
	</DefaultLayout>
</template>

<script async>
import destructureBlogArticles from '@/api/helpers/destructureBlogArticles.js'
import apiService from '@/api/index.js'
import AngledBackground from '@/assets/backgrounds/AngledBackground.vue'
import SearchResultRow from '@/components/search/SearchResultRow.vue'
import WwFaq from '@/components/UI/faq/WwFaq.vue'
import GET_MED_CARD_FAQ from '@/constants/faqs/get-med-card.js'
import LEAFWELL_COPY from '@/constants/getMedCard/leafwell-copy.js'
import { BLOGS_LIST } from '@/constants/search/listTypes.js'
import STATES from '@/constants/states.js'
import DefaultLayout from '@/layouts/default/Index.vue'
import HeroSection from '@/views/getMedCard/components/HeroSection.vue'

export default {
	components: {
		DefaultLayout,
		HeroSection,
		SearchResultRow,
		WwFaq,
		AngledBackground
	},
	data () {
		return {
			LEAFWELL_COPY,
			GET_MED_CARD_FAQ,
			BLOGS_LIST,
			articles: {},
			articleIds: [],
			leafwellUrl: 'https://wheresweed.leafwell.co?utm_source=wheresweed&utm_medium=leafwell_landing&utm_campaign_affiliate=form',
			selected: null,
			STATES
		}
	},
	computed: {
		blogArticles() {
			const articles = []
			for (let i = 0; i < this.articleIds.length; i++) {
				const article = this.articleIds[i]
				articles.push(this.articles[article])
			}
			return articles
		}
	},
	watch: {
		selected() {
			this.$router.push({ name: 'leafwellState', params: { state: this.selected } })
		}
	},
	mounted() {
		this.fetchArticles()
	},
	methods: {
		async fetchArticles() {
			const postData = {
				type: 'Blog',
				func_name: 'getCategoryPageData',
				data: {
					category: 'health'
				}
			}
			try {
				const response = await apiService.get(postData)
				if (response.data.errors) {
					throw response.data.errors[0].message
				}

				const blogs = destructureBlogArticles(response.data.results.blogs)
				for (let i = 0; i < blogs.length; i++) {
					this.articles[blogs[i].id] = Object.assign({}, blogs[i])
				}

				const ids = response.data.results.blogs.ids.slice(0, 6)
				this.articleIds = ids
			} catch (e) {
				alert(e)
			}
		}
	},
	metaInfo: {
		title: 'Where’s Weed Partners | Cannabis Friendly Solutions for POS, CRM & loyalty, compliance and consulting',
		meta: [
			{
				name: 'description',
				content: 'Want to work with Where’s Weed? Do you offer cannabis businesses solutions for POS, CRM & loyalty, compliance and consulting '
			}
		]
	}
}
</script>
