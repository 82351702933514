import { EMAIL, TEL, TEXT, TEXTAREA } from '@/constants/text-inputs/types.js'

export const FORM_NAME = 'contact_support_form'

export const CONTACT_SUPPORT_FORM = [
	{
		name: 'firstName',
		label: 'First Name',
		placeholder: 'First Name',
		inputType: TEXT,
		dataType: TEXT,
		required: true,
		autocomplete: 'given-name'
	},
	{
		name: 'lastName',
		label: 'Last Name',
		placeholder: 'Last Name',
		inputType: TEXT,
		dataType: TEXT,
		required: true,
		autocomplete: 'family-name'
	},
	{
		name: 'email',
		label: 'Email',
		placeholder: 'your@email.com',
		inputType: EMAIL,
		dataType: EMAIL,
		required: true,
		autocomplete: 'email'
	},
	{
		name: 'phone',
		label: 'Phone Number',
		placeholder: '(555) 555-5555',
		inputType: TEL,
		dataType: TEXT,
		required: false,
		autocomplete: 'tel'
	},
	{
		name: 'message',
		label: 'Message',
		placeholder: 'Write your message...',
		inputType: TEXTAREA,
		dataType: TEXT,
		required: true,
		autocomplete: 'off'
	}
]
