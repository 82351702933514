<template>
	<div
		v-if="faq"
		class="py-2 text-center md:text-left"
	>
		<div class="mb-2 font-bold text-purple-500 uppercase">
			{{ faq.header }}
		</div>
		<div class="mb-2 text-2xl font-bold">
			{{ faq.title }}
		</div>
		<div class="py-2 leading-relaxed">
			{{ faq.description }}
		</div>
		<div class="max-w-xl m-auto">
			<ul class="px-8 text-left list-disc">
				<li
					v-for="index in faq.bullets"
					:key="`faq-${index}`"
					class="mb-2 leading-relaxed"
				>
					{{ index }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script async>
export default {
	props: {
		faq: {
			type: Object,
			required: true
		}
	}
}
</script>
