<template>
	<div class="flex flex-wrap justify-center w-full pb-6 m-auto gap-y-6 justify-items-center md:pb-12">
		<div
			v-for="(card, index) in blogCards"
			:key="`${card.title}-${index}`"
			class="flex mx-auto bg-white border shadow-md rounded-xl md:w-72 lg:w-80 h-116 md:h-104"
		>
			<div class="w-full">
				<div class="grid w-full h-full min-w-full grid-cols-1">
					<div
						v-if="card.image"
						class="flex w-full h-full"
					>
						<img
							:src="card.image.imgSrc"
							:alt="card.image.name"
							class="object-cover w-full rounded-t-xl"
						>
					</div>

					<div class="flex flex-wrap h-auto">
						<div
							v-if="card.title"
							class="w-full px-4 text-xl font-bold text-left md:text-lg lg:text-xl md:mt-auto"
							:class="[card.titleClass ? card.titleClass : 'pt-1 lg:pt-6 xl:pt-6']"
						>
							<span v-html="card.title" />
						</div>

						<div
							v-if="card.content"
							class="relative w-full text-lg text-left md:text-base lg:text-lg"
							:class="[card.contentClass ? card.contentClass : 'px-4 mt-auto md:my-auto']"
						>
							<span v-html="card.content" />
						</div>

						<div
							v-if="card.buttons"
							class="flex flex-wrap content-end w-full px-4 pb-4 gap-y-5 md:gap-y-4"
						>
							<div
								v-for="(button, index) in card.buttons"
								:key="`${button}-${index}`"
								class="w-full"
								:class="card.buttons.length > 1 ? '' : ''"
							>
								<router-link
									class="w-full py-2 text-sm font-bold text-center text-white uppercase border-none rounded-sm shadow-sm"
									tag="button"
									:class="[button.color, card.buttons.length > 1 ? 'mb-0' : '']"
									:to="{ path: button.link }"
								>
									{{ button.text }}
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script async>
export default {
	props: {
		blogCards: {
			type: Array,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		}
	}
}
</script>
