<template>
	<DefaultLayout>
		<div class="flex flex-wrap max-w-6xl p-4 mx-auto">
			<div
				tabindex="0"
				class="order-2 w-full text-lg leading-loose text-left lg:w-1/2 lg:order-1"
			>
				<p class="py-2">
					<span class="font-bold">
						Where's Weed
					</span> is the comprehensive online consumer resource that connects visitors with relevant cannabis businesses across the US and Canada. We showcase a variety of different directories to help people find dispensaries, delivery services, MMJ doctors, smoke shops, branded products and related deals -- plus relevant cannabis news and culture.
				</p>

				<p class="py-2">
					At our core, we're a <span class="font-bold">
						technology company
					</span> with a passionate team who truly cares about the consumer. Since 2011, we've led with education to allow visitors to safely experience the legalized cannabis industry, while providing a platform where canna-businesses can advertise, legally.
				</p>

				<p class="py-2">
					Whether you're looking for a new favorite dispensary, want to order products online or read the latest cannabis news & culture, we've got your back!
				</p>

				<h3 class="pt-4 mb-2 text-2xl font-bold">
					Questions?
				</h3>
				<a
					href="/contact"
					class="px-4 py-1 rounded-full text-purple-500 border border-solid border-purple-500 font-bold my-5 py-2 hover:bg-purple-500 hover:text-white"
				>
					Contact Us
				</a>
			</div>
			<div class="flex justify-center order-1 w-full lg:w-1/2 lg:order-2">
				<img
					src="/img/about.jpg"
					class="p-4"
					height="664"
					width="560"
					alt="Our Story"
				>
			</div>
		</div>
	</DefaultLayout>
</template>

<script async>
import DefaultLayout from '@/layouts/default/Index.vue'

export default {
	components: {
		DefaultLayout
	},
	metaInfo: {
		title: 'About Us - Wheresweed.com',
		meta: [
			{ description: 'Where\'s Weed is the comprehensive online consumer resource that connects visitors with relevant cannabis businesses across the US and Canada. We showcase a variety of different directories to help people find dispensaries, delivery services, MMJ doctors, smoke shops, branded products and related deals -- plus relevant cannabis news and culture.' },
			{ canonical: '/about' }
		]
	}
}
</script>
