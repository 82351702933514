<template>
	<DefaultLayout>
		<div class="grid grid-cols-1 p-4 m-auto justify-items-center md:py-4">
			<Breadcrumbs
				:breadcrumbs="breadcrumbs"
				class="mt-2 mb-4"
			/>
			<div
				v-if="!showSuccessMessage"
				class="flex w-full"
			>
				<div class="flex-initial pb-4 m-auto mb-4">
					<div class="pt-3 mb-4 text-center">
						<h1 class="px-16 mb-4 text-3xl font-bold md:px-0">
							Get in touch with Sales
						</h1>
						<p class="px-12 pb-4 mx-auto mb-4 text-gray-500 md:px-0">
							Our team will reach out to you in the next 24 hours.
						</p>
					</div>
					<contact-form
						:input-fields="CONTACT_SALES_FORM"
						:form-name="FORM_NAME"
						@show-success-message="showSuccessMessage = true"
					/>
				</div>
			</div>
			<contact-form-success v-else />
		</div>
	</DefaultLayout>
</template>

<script async>
import Breadcrumbs from '@/components/multiUse/Breadcrumbs.vue'
import ContactForm from '@/components/multiUse/ContactForm.vue'
import ContactFormSuccess from '@/components/multiUse/ContactFormSuccess.vue'
import { CONTACT_SALES_FORM, FORM_NAME } from '@/constants/contactHub/contact-sales-form.js'
import { APP_META } from '@/constants/index.js'
import DefaultLayout from '@/layouts/default/Index.vue'

export default {
	components: {
		DefaultLayout,
		Breadcrumbs,
		ContactFormSuccess,
		ContactForm
	},
	data() {
		return {
			APP_META,
			CONTACT_SALES_FORM,
			FORM_NAME,
			meta: {
				title: `Sales ${APP_META.DOMAIN_NAME}`,
				description: `Sales ${APP_META.DOMAIN_NAME}`,
				keywords: '',
				canonical: '/sales'
			},
			showSuccessMessage: false
		}
	},
	metaInfo: {
		title: `Contact Sales - ${APP_META.DOMAIN_NAME}`,
		meta: [
			{ description: `Contact Sales - ${APP_META.DOMAIN_NAME}` },
			{ canonical: '/sales' }
		]
	},
	computed: {
		breadcrumbs() {
			const crumbs = [ {
				url: '/', content: 'Home', srOnly: true
			} ]
			crumbs.push({ url: '/contact', content: 'Back to Contact' })
			return crumbs
		}
	}
}
</script>
