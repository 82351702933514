<template>
	<DefaultLayout>
		<div class="max-w-6xl p-4 mx-auto text-left">
			<h1 class="pb-3 text-2xl font-semibold">
				{{ APP_META.NAME }} Terms of Use
			</h1>
			<p class="pb-4 prose-lg">
				Effective Date: These Terms of Use were last updated on 3/15/2021.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Acceptance of the Terms of Use
			</h2>
			<p class="pb-3 prose-lg">
				These terms of use are entered into by and between you and Viath, Inc ("<strong>
					Viath
				</strong>," "<strong>
					we
				</strong>," or "<strong>
					us
				</strong>"). The following terms and conditions, together with the Business Terms below and any documents they expressly incorporate by reference (collectively, "<strong>
					Terms of Use
				</strong>"), govern your access to and use of <router-link
					class="text-green-500"
					to="/"
				>
					https://{{ APP_META.DOMAIN }}
				</router-link>, including any content, functionality, and services offered on or through <router-link
					class="text-green-500"
					to="/"
				>
					https://{{ APP_META.DOMAIN }}
				</router-link> (the "<strong>
					Site
				</strong>"), whether as a guest, a registered user, or on behalf of a business.
			</p>

			<p class="pb-3 prose-lg">
				Please read the Terms of Use carefully before you start to use the Site. <strong>
					By using the Site, you accept and agree to be bound and abide by these Terms of Use, our Privacy Policy, found at [<router-link
						class="text-green-500"
						to="/privacy"
					>
						https://{{ APP_META.DOMAIN }}/privacy
					</router-link>] and the other terms, conditions, and policies published on the Site, in all cases, incorporated herein by reference.
				</strong> If you do not want to agree to these Terms of Use you must not access or use the Site.
			</p>

			<p
				style="font-weight: bold;"
				class="pb-3 prose-lg"
			>
				PLEASE READ THESE TERMS OF USE CAREFULLY AS THEY PROVIDE FOR THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN TRIALS OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE. BY ACCESSING THE SITE AND SERVICES, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND AGREE TO THE DISPUTE RESOLUTION PROVISIONS HEREIN.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Eligibility
			</h2>
			<p class="pb-4 prose-lg">
				The Site and the Viath Services function as a cannabis or medical cannabis business locator service for medical patients and adult users in jurisdictions where medical and/or adult cannabis use is legally permissible. <strong>
					Viath is a technology service provider only
				</strong> and is not a merchant of cannabis or cannabis products, or provider of any retail, pickup, or delivery services in connection therewith, and does not control the participating dispensaries or other businesses featured on the Site (hereafter the "<strong>
					Dispensary
				</strong>" or "<strong>
					Dispensaries
				</strong>") or the production of any cannabis of cannabis products, or any retail, pickup, or delivery services therewith.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				The Site, Viath's Services and Legal Acknowledgements
			</h2>
			<p class="pb-3 prose-lg">
				The Site and the Viath Services function as a cannabis or medical cannabis dispensary locator service for medical patients and adult users in jurisdictions where medical and/or adult cannabis use is legally permissible. <strong>
					<u>
						Viath is a technology service provider only
					</u>
				</strong> and is not a merchant of cannabis or cannabis products, or provider of any retail, pickup, or delivery services in connection therewith, and does not control the participating dispensaries or other businesses featured on the Site (the "<strong>
					Dispensary
				</strong>" or "<strong>
					Dispensaries
				</strong>") or the production of any cannabis of cannabis products, or any retail, pickup, or delivery services therewith.
			</p>

			<p class="pb-3 prose-lg">
				Viath operates under applicable state laws. By accessing the Site, you expressly acknowledge and understand that marijuana (cannabis) is listed on Schedule I of the United States Controlled Substances Act ("<strong>
					CSA
				</strong>"), that under the federal laws of the United States of America, manufacturing, distributing, dispensing, or possession of marijuana is illegal, and that individuals are subject to arrest and/or prosecution for doing so. You further acknowledge that medical use is not recognized as a valid defense under federal laws regarding marijuana. You also acknowledge and understand that the interstate transportation of marijuana is a federal offense. You must abide by and follow the applicable laws of the state, city, county, municipality, or jurisdiction in which you are located in order to use the Site. You acknowledge that the information contained in these Terms of Use and the information provided on the Site was developed for informational and educational purposes only. Engaging in activities or business related to cannabis is at your own risk.
			</p>

			<p class="pb-3 prose-lg">
				Viath does not in any way verify the credentials or representations of any of the Dispensaries, the information provided by the Dispensaries on the Site, the quality of their products or services, or any Dispensaries' compliance with applicable laws.
			</p>

			<p class="pb-3 prose-lg">
				Viath does not guarantee the quality of any Dispensary or any product or service offered in connection therewith, or any compliance thereof with applicable laws. Additionally, a Dispensary may represent certain standards with respect to their products such as "organic" or "sustainable;" Viath does not investigate or verify any such representations. Viath shall not be liable or responsible for any statements, products, or services offered by the Dispensaries or any errors or misrepresentations made by them (including on the Site).
			</p>

			<p class="pb-4 prose-lg">
				Viath is not a party to any transaction you enter into with any Dispensary or other third party in connection with the Site, unless otherwise expressly indicated herein. Any such activity, and any terms, conditions, warranties, or representations associated with such activity, is solely between you and the applicable third party. Viath and its affiliates, service providers, and licensors shall have no liability, obligation, or responsibility for any purchase, correspondence, promotion, or transaction between you and any third party. In no event shall Viath or its affiliates, service providers, or licensors be responsible for any content, products, services, or other materials on or available from third parties.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Changes to the Terms of Use
			</h2>
			<p class="pb-4 prose-lg">
				We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them. Your continued use of the Site following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page each time you access this Site so you are aware of any changes, as they are binding on you.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Accessing the Site and Account Security
			</h2>
			<p class="pb-3 prose-lg">
				We are under no obligation to allow Site access or account registration to any end user or Dispensary and reserve the right to withdraw or amend this Site, and any service or content provided on the Site, in our sole and complete discretion without notice or liability. We reserve the right to determine in our sole discretion who is eligible to use the Site and may change our eligibility criteria at any time. We may accept or reject any account registration request, add registration requests to a waitlist that we manage in our sole discretion, impose restrictions or limitations on accounts, terminate accounts or decline to offer access to our Site for any or no reason, in our sole and complete discretion including, without limitation, if we determine that a user has violated these Terms of Use.
			</p>

			<p class="pb-3 prose-lg">
				If these things are prohibited by law where you live, then we hereby revoke your right to use the Site in that jurisdiction. We are not liable for any damages as a result of any of these actions
			</p>

			<p class="pb-3 prose-lg">
				We reserve the right to withdraw or amend this Site, and any service or material we provide on the Site, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Site is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Site, or the entire Site, to users, including registered users.
			</p>

			<p class="pb-3 prose-lg">
				You are responsible for both:
			</p>
			<ul class="pb-3 prose-lg">
				<li>
					Making all arrangements necessary for you to have access to the Site.
				</li>
				<li>
					Ensuring that all persons who access the Site through your internet connection are aware of these Terms of Use and comply with them.
				</li>
			</ul>

			<p class="pb-3 prose-lg">
				To access the Site or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Site that all the information you provide on the Site is correct, current, and complete. You agree that all information you provide to register with this Site or otherwise, including, but not limited to, through the use of any interactive features on the Site, is governed by our <router-link
					class="text-green-500"
					to="/privacy"
				>
					Privacy Policy
				</router-link>, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.
			</p>

			<p class="pb-3 prose-lg">
				If you choose, or are provided with, a user name, password, or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Site or portions of it using your user name, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.
			</p>

			<p class="pb-4 prose-lg">
				We have the right to disable any user name, password, or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Communications
			</h2>
			<p class="pb-4 prose-lg">
				By accessing the Site, you expressly consent and agree to accept and receive communications from Viath, other users, and/or Dispensaries that you transact with, including via email, text message, calls, and push notifications to the cellular telephone number you provided to us. Please see our <router-link
					class="text-green-500"
					to="/privacy"
				>
					Privacy Policy
				</router-link> for more information about your communication and notification options.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Intellectual Property Rights
			</h2>
			<p class="pb-3 prose-lg">
				The Site and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by Viath, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. All trademarks not owned by Viath that appear on the Site are the property of their respective owners.
			</p>

			<p class="pb-3 prose-lg">
				Unless Viath has a separate written agreement with you or has given you permission pursuant to the Business Terms below, you may not use any of Viath's trademarks, service marks, or trade names in or with your links. You may not present a link to the Site in any way that suggests Viath has any relationship or affiliation with your web site or endorses, sponsors, or recommends the information, products or services on your site, unless you have a specific written agreement with Viath to do so. You may link to the Site using the plain text name of the Site. Link only to the home page of the Site. Do not, without Viath's written permission: (a) incorporate any of our content into your web site (e.g., by in-lining or framing); (b) use any of Viath's trademarks, service marks, taglines, slogans, trade names or any other words or codes identifying Viath or the Site in any "metatag". Viath will not tolerate links from any web site that may adversely affect the name, reputation and/or goodwill of Viath. Viath reserves the right to terminate permission to link to the Site at any time, for any reason.
			</p>

			<p class="pb-3 prose-lg">
				You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Site, except as follows:
			</p>

			<ul class="pb-3 prose-lg">
				<li>
					Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.
				</li>
				<li>
					You may store files that are automatically cached by your Web browser for display enhancement purposes.
				</li>
				<li>
					If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.
				</li>
				<li>
					If we provide social media features with certain content, you may take such actions as are enabled by such features.
				</li>
			</ul>

			<p class="pb-3 prose-lg">
				You must not:
			</p>

			<ul class="pb-3 prose-lg">
				<li>
					Modify copies of any materials from the Site.
				</li>
				<li>
					Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from the Site.
				</li>
			</ul>

			<p class="pb-3 prose-lg">
				Except as authorized by Viath under separate written agreement or terms, or pursuant to the Business Terms below, you must not access or use for any commercial purposes any part of the Site or any services or materials available through the Site.
			</p>

			<p class="pb-4 prose-lg">
				If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Site in breach of the Terms of Use, your right to use the Site will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Site or any content on the Site is transferred to you, and all rights not expressly granted are reserved by Viath. Any use of the Site not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Prohibited Uses
			</h2>
			<p class="pb-3 prose-lg">
				You may use the Site only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Site:
			</p>

			<ul class="pb-3 prose-lg">
				<li>
					In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries, but excluding the CSA).
				</li>

				<li>
					For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.
				</li>

				<li>
					To send, knowingly receive, upload, download, use, or re-use any material that does not comply with the Content Standards [LINK TO CONTENT STANDARDS SECTION] set out in these Terms of Use.
				</li>

				<li>
					To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any "junk mail," "chain letter," "spam," or any other similar solicitation.
				</li>

				<li>
					To impersonate or attempt to impersonate Viath, Where's Weed, a Viath employee, another user, or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing).
				</li>

				<li>
					To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Site, or which, as determined by us, may harm Viath or users of the Site, or expose them to liability.
				</li>
			</ul>

			<p class="pb-3 prose-lg">
				Additionally, you agree not to:
			</p>

			<ul class="pb-3 prose-lg">
				<li>
					Use the Site in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Site, including their ability to engage in real time activities through the Site.
				</li>

				<li>
					Use any robot, spider, or other automatic device, process, or means to access the Site for any purpose, including monitoring or copying any of the material on the Site.
				</li>

				<li>
					Use any manual process to monitor or copy any of the material on the Site, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent.
				</li>

				<li>
					Use any device, software, or routine that interferes with the proper working of the Site.
				</li>

				<li>
					Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.
				</li>

				<li>
					Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Site, the server on which the Site is stored, or any server, computer, or database connected to the Site.
				</li>

				<li>
					Attack the Site via a denial-of-service attack or a distributed denial-of-service attack.
				</li>

				<li>
					Otherwise attempt to interfere with the proper working of the Site.
				</li>
			</ul>

			<p class="pb-4 prose-lg">
				We are under no obligation to enforce the Terms of Use on your behalf against another user. While we encourage users to let us know if they believe another user has violated the Terms of Use, we reserve the right to investigate and take appropriate action at our sole discretion.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				User Contributions
			</h2>

			<p class="pb-3 prose-lg">
				The Site may contain review and comment features, message boards, chat functionality, personalized web pages or profiles, forums, bulletin boards, and other interactive features (collectively, "<strong>
					Interactive Services
				</strong>") that allow users to post, submit, publish, display, or transmit to other users or other persons (hereinafter, "<strong>
					post
				</strong>") reviews, text, graphics, photographs, logos, images, audio clips, digital downloads, and other data, content or materials (collectively, "<strong>
					User Contributions
				</strong>") on or through the Site.
			</p>

			<p class="pb-3 prose-lg">
				All User Contributions must comply with the Content Standards set out in these Terms of Use.
			</p>

			<p class="pb-3 prose-lg">
				Any User Contribution you post to the Site will be considered non-confidential and non-proprietary. By providing any User Contribution on the Site, you hereby irrevocably grant us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns the worldwide, perpetual, non-exclusive, royalty-free, assignable, sublicensable, transferable right to use, reproduce, modify, perform, display, distribute, make derivative works of, and otherwise disclose to third parties any such material for any purpose.
			</p>

			<p class="pb-3 prose-lg">
				You represent and warrant that:
			</p>

			<ul class="pb-3 prose-lg">
				<li>
					You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns.
				</li>
				<li>
					All of your User Contributions do and will comply with these Terms of Use.
				</li>
			</ul>

			<p class="pb-3 prose-lg">
				You understand and acknowledge that you are solely responsible for any User Contributions you submit or contribute, and you, not Viath, have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness.
			</p>

			<p class="pb-3 prose-lg">
				We are not responsible or liable to any third party for the content or accuracy of any User Contributions posted by you or any other user of the Site.
			</p>

			<p class="pb-4 prose-lg">
				Finally, you irrevocably waive, and cause to be waived, against us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns any claims and assertions of moral rights or attribution with respect to your User Contributions.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Monitoring and Enforcement; Termination
			</h2>
			<p>
				We have the right to:
			</p>

			<ul class="pb-3 prose-lg">
				<li>
					Remove or refuse to post any User Contributions for any or no reason in our sole discretion.
				</li><li>
					Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including without limitation if we believe that such User Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Site or the public, or could create liability for Viath.
				</li>

				<li>
					Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.
				</li>

				<li>
					Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Site.
				</li>

				<li>
					Terminate or suspend your access to all or part of the Site for any or no reason, including without limitation, any violation of these Terms of Use.
				</li>
			</ul>

			<p class="pb-3 prose-lg">
				Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Site.
			</p>

			<p class="pb-3 prose-lg">
				YOU WAIVE AND HOLD HARMLESS VIATH AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
			</p>

			<p class="pb-4 prose-lg">
				However, we cannot and do not undertake to review material before it is posted on the Site, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Content Standards
			</h2>
			<p class="pb-3 prose-lg">
				These content standards apply to any and all User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local, and international laws and regulations (excluding the CSA but including prohibitions against the interstate transport of marijuana). Without limiting the foregoing, User Contributions must not:
			</p>

			<ul class="pb-3 prose-lg">
				<li>
					Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.
				</li>

				<li>
					Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.
				</li>

				<li>
					Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person.
				</li>

				<li>
					Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our <router-link to="/privacy">
						Privacy Policy
					</router-link>.
				</li>

				<li>
					Be likely to deceive any person.
				</li>

				<li>
					Promote any illegal activity, or advocate, promote, or assist any unlawful act.
				</li>

				<li>
					Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other person.
				</li>

				<li>
					Impersonate any person or misrepresent your identity or affiliation with any person or organization.
				</li>

				<li>
					Give the impression that they emanate from or are endorsed by Viath or any other person or entity, if this is not the case.
				</li>
			</ul>

			<p class="pb-3 prose-lg">
				Reviews are not endorsed by Viath, and do not represent the views of Viath or of any affiliate or partner of Viath. Viath does not assume liability for any review or for any claims, liabilities or losses resulting from any review. If you are posting a review of a Dispensary, your review must also comply with the following criteria:
			</p>

			<ul class="pb-4 prose-lg">
				<li>
					You must have firsthand experience with the Dispensary.
				</li>
				<li>
					You must not be an owner or employee of the Dispensary, or affiliated with any competitors of the Dispensary.
				</li>
				<li>
					You should not make any conclusions as to the legality of the Dispensary's products, services, or conduct.
				</li>
			</ul>

			<h2 class="pb-3 text-xl font-semibold">
				Copyright Infringement
			</h2>

			<p class="pb-4 prose-lg">
				Viath respects the intellectual property rights of others. If you believe in good faith that any User Contributions violate your copyright, please see our [<router-link
					class="text-green-500"
					to="/copyright"
				>
					Copyright Policy
				</router-link>] for instructions on sending us a notice of copyright infringement. It is the policy of Viath to terminate the user accounts of repeat infringers.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Reliance on Information Posted
			</h2>
			<p class="pb-3 prose-lg">
				The information presented on or through the Site is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk.
			</p>

			<p class="pb-3 prose-lg">
				<strong>
					WE DISCLAIM ALL LIABILITY AND RESPONSIBILITY ARISING FROM ANY RELIANCE PLACED ON SUCH MATERIALS BY YOU OR ANY OTHER VISITOR TO THE SITE, OR BY ANYONE WHO MAY BE INFORMED OF ANY OF ITS CONTENTS.
				</strong>
			</p>

			<p class="pb-4 prose-lg">
				This Site includes content provided by third parties, including materials provided by other users, bloggers, and third-party licensors, syndicators, aggregators, and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by Viath, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of Viath. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Changes to the Site
			</h2>
			<p class="pb-4 prose-lg">
				We may update the content on the Site from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Site may be out of date at any given time, and we are under no obligation to update such material.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Information About You and Your Visits to the Site
			</h2>
			<p class="pb-4 prose-lg">
				All information we collect on this Site is subject to our [<router-link
					class="text-green-500"
					to="/privacy"
				>
					Privacy Policy
				</router-link>]. By using the Site, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Online Pre-Orders and Other Terms and Conditions
			</h2>
			<p class="pb-4 prose-lg">
				Pick-up reservations and other pre-orders ("<strong>
					Pre-Orders
				</strong>") are not pre-sales and all sales transactions are strictly between the end user and the Dispensary. Verification of information may be required prior to the acceptance of a Pre-Order. When you initiate a Pre-Order, we collect information necessary to complete your request, such as your contact information, order information, government-issued ID (if necessary), and, when necessary, a valid delivery address. In some instances, you may also submit data that may be considered personal medical information, such as medical marijuana patient identification for certain Pre-Orders placed through our Site. We use this information to facilitate your Pre-Order (and future orders), including processing your request and sending the information to the Dispensary you intend to have fulfill your order. The receipt of an order number or confirmation does not constitute the Dispensary's acceptance of an order or a confirmation of an offer to sell. Prices and availability of products on the Site are subject to change without notice. We reserve the right, at our sole discretion, to refuse or cancel any Pre-Order for any reason. Pre-Orders made through our Site or other transactions formed through the Site, or resulting from visits made by you, are governed by the additional terms and conditions presented at the time of transaction, if any. All such additional Viath terms and conditions are hereby incorporated by this reference into these Terms of Use.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Linking to the Site and Social Media Features
			</h2>

			<p class="pb-3 prose-lg">
				You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part without our express written consent.
			</p>
			<p>
				This Site may provide certain social media features or otherwise enable you to:
			</p>

			<ul class="pb-3 prose-lg">
				<li>
					Link from your own or certain third-party websites to certain content on this Site.
				</li>

				<li>
					Send emails or other communications with certain content, or links to certain content, on this Site.
				</li>

				<li>
					Cause limited portions of content on this Site to be displayed or appear to be displayed on your own or certain third-party websites.
				</li>
			</ul>

			<p class="pb-3 prose-lg">
				You may use these features solely as they are provided by us and solely with respect to the content they are displayed with and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not:
			</p>

			<ul class="pb-3 prose-lg">
				<li>
					Establish a link from any website that is not owned by you.
				</li>

				<li>
					Cause the Site or portions of it to be displayed on, or appear to be displayed by, any other site, for example, framing, deep linking, or in-line linking.
				</li>

				<li>
					Link to any part of the Site other than the homepage.
				</li>

				<li>
					Otherwise take any action with respect to the materials on this Site that is inconsistent with any other provision of these Terms of Use.
				</li>
			</ul>

			<p class="pb-4 prose-lg">
				The website from which you are linking, or on which you make certain content accessible, must comply in all respects with the Content Standards set out in these Terms of Use. You agree to cooperate with us in causing any unauthorized framing or linking immediately to stop. We reserve the right to withdraw linking permission without notice. We may disable all or any social media features and any links at any time without notice in our discretion.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Links from the Site
			</h2>
			<p class="pb-4 prose-lg">
				If the Site contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to this Site, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Geographic Restrictions
			</h2>
			<p class="pb-4 prose-lg">
				The owner of the Site is based in the State of Colorado in the United States. We provide this Site for use only by persons located in the United States and Canada. We make no claims that the Site or any of its content is accessible or appropriate outside of the United States or Canada. Access to the Site may not be legal by certain persons or in certain countries. If you access the Site from outside the United States or Canada, you do so on your own initiative and are responsible for compliance with local laws.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Disclaimer of Warranties
			</h2>
			<p class="pb-3 prose-lg">
				You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Site will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data.
			</p>

			<p class="pb-3 prose-lg">
				TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
			</p>

			<p class="pb-3 prose-lg">
				YOUR USE OF THE SITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE IS AT YOUR OWN RISK. THE SITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER VIATH NOR ANY PERSON ASSOCIATED WITH VIATH MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SITE. WITHOUT LIMITING THE FOREGOING, NEITHER VIATH NOR ANYONE ASSOCIATED WITH VIATH REPRESENTS OR WARRANTS THAT THE SITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
			</p>

			<p class="pb-4 prose-lg">
				TO THE FULLEST EXTENT PROVIDED BY LAW, VIATH HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Limitation on Liability
			</h2>

			<p class="pb-3 prose-lg">
				TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL VIATH, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE SITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
			</p>

			<p class="pb-3 prose-lg">
				The limitation of liability set out above does not apply to liability resulting from Viath, Inc's gross negligence or willful misconduct.
			</p>

			<p class="pb-4 prose-lg">
				THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Indemnification
			</h2>
			<p class="pb-4 prose-lg">
				You agree to defend, indemnify, and hold harmless Viath, its affiliates, related companies, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the Site, including, but not limited to, your User Contributions, any use of the Site's content, services, and products other than as expressly authorized in these Terms of Use, your products or services, or the marketing or provision thereof to end users, any infringement by you, or any third party using your account, of any intellectual property or other right of any person or entity, or your use of any information obtained from the Site. Viath reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims. You agree not to settle any such matter without Viath's prior written consent.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Governing Law and Jurisdiction
			</h2>
			<p class="pb-3 prose-lg">
				All matters relating to the Site and these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Colorado without giving effect to any choice or conflict of law provision or rule.
			</p>

			<p class="pb-4 prose-lg">
				Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use or the Site shall be instituted exclusively in the district courts of the United States or the courts of the State of Colorado, in each case located in the City and County of Denver. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Arbitration
			</h2>
			<p class="pb-4 prose-lg">
				At our sole discretion, we may require you to submit any disputes arising from these Terms of Use or use of the Site, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of JAMS applying Colorado state law.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Limitation on Time to File Claims
			</h2>
			<p class="pb-4 prose-lg">
				ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE SITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Waiver and Severability
			</h2>
			<p class="pb-3 prose-lg">
				No waiver by Viath of any term or condition set out in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Viath to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.
			</p>

			<p class="pb-4 prose-lg">
				If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Export Control
			</h2>
			<p class="pb-4 prose-lg">
				You agree to comply fully with all U.S. and foreign export laws and regulations to ensure that neither the Site nor any technical data related thereto nor any direct product thereof is exported or re-exported directly or indirectly in violation of, or used for any purposes prohibited by, such laws and regulations. By using the Site, you represent and warrant that: (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Entire Agreement and Assignment
			</h2>
			<p class="pb-4 prose-lg">
				The Terms of Use, our Privacy Policy, and the other terms and conditions incorporated herein by reference constitute the sole and entire agreement between you and Viath regarding the Site and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Site. These Terms of Use and any related guidelines, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by Viath without restriction.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Notice for California Users
			</h2>
			<p class="pb-4 prose-lg">
				Under California Civil Code Section 1789.3, California users are entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 400 R Street, Suite 1080, Sacramento, California 95814, or by telephone at (916) 445-1254 or (800) 952-5210.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Your Comments and Concerns
			</h2>
			<p class="pb-3 prose-lg">
				This website is operated by Viath Inc, 8547 E Arapahoe Rd, Ste J #436, Greenwood Village, CO 80112.
			</p>

			<p class="pb-3 prose-lg">
				All notices of copyright infringement claims should be sent to the copyright agent designated in our <router-link
					class="text-green-500"
					to="/copyright"
				>
					Copyright Policy
				</router-link> in the manner and by the means set out therein.
			</p>

			<p class="pb-3 prose-lg">
				All other feedback, comments, requests for technical support, and other communications relating to the Site should be directed to: [<a
					class="text-green-500"
					href="mailto:contact@wheresweed.com"
				>
					contact@wheresweed.com
				</a>].
			</p>
			<p class="pb-3 prose-lg">
&nbsp;
			</p>
			<p class="pb-4 prose-lg">
&nbsp;
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Additional Terms For Business Accounts
			</h2>
			<p class="pb-3 prose-lg">
				Last updated: 3/15/2021
			</p>

			<p class="pb-3 prose-lg">
				The following terms ("<strong>
					Business Terms
				</strong>"), in addition to the Terms of Use above, govern your access to and use of your Business Account.  "<strong>
					Business Account
				</strong>" means the Dispensary business account you created to access on the Site and includes both free and paid accounts.
			</p>

			<p class="pb-3 prose-lg">
				In the event of any conflict between these Business Terms and the Terms of Use, the Business Terms apply. If you have purchased packages, products, or services from Viath on behalf of your business, the terms of that purchase, if any, apply in the event of any conflict with these Business Terms. Capitalized words used but not defined in these Business Terms have the meanings described in the Terms of Use. By creating, accessing, or using your Business Account, you are agreeing to these Business Terms and concluding a legally binding contract with Viath. You are not authorized to create, access, or use a Business Account if you do not agree to these Business Terms.
			</p>

			<p class="pb-3 prose-lg">
				<strong>
					PLEASE READ THESE BUSINESS TERMS CAREFULLY AS THEY REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN TRIALS OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.
				</strong>
			</p>

			<p class="pb-4 prose-lg">
				In the event of any termination of your Business Account, whether by you or us, these Business Terms in their entirety will continue in full force and effect.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Requirements, Representations and Warranties
			</h2>
			<p class="pb-3 prose-lg">
				In order to access or use the Services, you agree that:
			</p>

			<ol class="pb-3 prose-lg">
				<li>
					you have the authority to act on behalf of the business or businesses associated with or claimed through your Business Account and bind any such business (including any corresponding business entity) to the Business Terms (such business or businesses, your "Business");
				</li>

				<li>
					your access to or use of the Business Site will only be in your capacity as an authorized representative of your Business;
				</li>

				<li>
					you will not use the Site to harass or improperly communicate with consumers or other businesses, including but not limited to by flagging reviews or messaging people who have reviewed your Business;
				</li>

				<li>
					your Business complies with applicable state and local laws and regulations regarding the sale and distribution of cannabis and you will not use the Site to offer or sell products or services that violate any state or local laws or regulations applicable to your Business;
				</li>

				<li>
					you understand that we may, in our sole discretion, remove or prohibit the listing of any product or other content associated with your Business Account that we believe may violate state or local laws or these Terms of Use;
				</li>

				<li>
					you have provided Viath with complete, accurate, and up-to-date information regarding your Business and will update such information in the event of any changes;
				</li>

				<li>
					you grant Viath a non-transferable, non-exclusive, royalty-free limited license to display your Business information and public website on the Services, or allow for its display through iframes or other framing technology;
				</li>

				<li>
					you agree that we may contact you, including by phone or email, using the contact information you provide us, make publicly available, or that we have on record for your business, and that our communications (including phone calls) with you may be monitored and recorded for quality purposes;
				</li>

				<li>
					you understand that we may refuse your request to modify certain information related to your Business Account, including the name of your Dispensary, in our sole discretion;
				</li>

				<li>
					you understand that we may disable access to your Business Account at any time if we believe, in our sole discretion, that you may be in violation of state or local laws or of these Business Terms; and
				</li>

				<li>
					you understand and acknowledge that non-disparagement clauses in certain consumer contracts, such as clauses that seek to restrict or prohibit reviews (including provisions that penalize consumers for posting reviews) about your Business, may be prohibited under state law and under the federal Consumer Review Fairness Act (15 U.S. Code § 45b) and you agree that you will not include such clauses in your consumer contracts, or otherwise attempt to enforce non-disparagement or 'gag' clauses against consumers under any circumstances.
				</li>
			</ol>

			<p class="pb-3 prose-lg">
				You represent and warrant that you will not, and will not authorize or induce any other party, to:
			</p>

			<ol class="pb-4 prose-lg">
				<li>
					offer incentives of any kind, such as discounts, freebies, refunds, gift cards, contest entries, offers, or deals in exchange for the posting of reviews of your Business, or to prevent or remove reviews, and you understand and acknowledge that Viath may publicly notify consumers about such incentives and other attempts to obtain, prevent, or remove reviews;
				</li>

				<li>
					solicit or ask for reviews from your customers;
				</li>

				<li>
					write reviews for your Business or your Business's competitors;
				</li>

				<li>
					attempt to generate automated, fraudulent, or otherwise invalid ad impressions, inquiries, conversions, ad clicks, or other actions;
				</li>

				<li>
					use any automated means or form of scraping or data extraction to access, query or otherwise collect our data, content and/or reviews from the Site; or
				</li>

				<li>
					misrepresent your identity or affiliation to anyone in connection with Viath.
				</li>
			</ol>

			<h2 class="pb-3 text-xl font-semibold">
				Paid Accounts
			</h2>

			<p class="pb-3 prose-lg">
				If you purchase a subscription package in connection with your Business Account, you will be responsible for paying the first monthly subscription fee ("<strong>
					Subscription Fee
				</strong>") at the time of sign-up. By purchasing a subscription package, you agree to an initial and recurring Subscription Fee at the then-current rate subject to the terms of any approved promotional codes used, and you accept responsibility for all recurring charges prior to cancellation. Your monthly subscription will automatically renew as follows:
			</p>

			<ul class="pb-3 prose-lg">
				<li>
					Your Subscription Fee will become due and payable on the anniversary date of each month ("Monthly Due Date"). We may adjust your Monthly Due Date +/-5 days in order to keep you in the same month./
				</li><li /><li>
					You agree and understand that if you wish to cancel or change your subscription package at any time, you must notify us at least two weeks before your Monthly Due Date to avoid being charged.
				</li>
			</ul>

			<p class="pb-3 prose-lg">
				Unless you have made other payment arrangements in a separate written agreement between you and Viath, you understand that you must maintain a valid credit card or pre-paid card on file with us at all times in order for us to process your Subscription Fee. Please be sure to log into your Business Account to update your credit card information in the event it changes. <strong>
					We reserve the right to terminate or suspend your Business Account at any time if we do not receive timely payment.
				</strong>
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Trademarks
			</h2>

			<p class="pb-3 prose-lg">
				The Where's Weed Creative Assets (as defined in our <router-link
					class="text-green-500"
					to="/branding"
				>
					Brand Guidelines
				</router-link> and incorporated herein by reference) are trademarks or trade dress of Viath and/or its related entities in the U.S. and other countries, whether or not federal registration has been pursued. The Where's Weed Creative Assets may not be used, except strictly as set forth in our Brand Guidelines. Subject to compliance with these Terms of Use, including the Business Terms, Viath grants you a non-transferable, non-exclusive, royalty-free limited license to use the Where's Weed Creative Assets to refer to Where's Weed or its Services, and only in accordance with the Brand Guidelines. This is not a trademark license.
			</p>

			<p class="pb-4 prose-lg">
				We reserve the right in our sole discretion to terminate or modify your permission to display the Where's Weed Creative Assets and to take action against any use that does not conform to these Terms of Use, Business Terms, or the Brand Guidelines, infringes any Where's Weed Creative Assets or other right, or violates applicable law. Except as set forth above, nothing herein grants or should be deemed to grant to you any right, title, or interest in or to the Where's Weed Creative Assets. Your use of the Where's Weed Creative Assets will inure to the benefit of Viath, and Viath disclaims any warranties either expressed or implied by law regarding the Where's Weed Creative Assets, including warranties of noninfringement.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Indemnification
			</h2>
			<p class="pb-4 prose-lg">
				In addition to the indemnification provided above in the Terms of Use, you further agree to defend, indemnify, and hold harmless Viath, its affiliates, related companies, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Business Terms or your use of the Site for your Business.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Disclaimers and Limitations of Liability
			</h2>
			<p class="pb-3 prose-lg">
				<strong>
					PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF THE VIATH ENTITIES TO YOU. FOR CLARITY, THE BELOW APPLIES IN ADDITION TO THE DISCLAIMERS AND LIMITATIONS OF LIABILITY DETAILED IN THE TERMS OF USE.
				</strong>
			</p>

			<p class="pb-4 prose-lg">
				The federal Communications Decency Act (47 U.S. Code § 230) limits the liability of interactive computer services, like Viath, for their role in publishing third-party content, including consumer reviews. Additionally, anti-SLAPP laws may require you to pay Viath's attorneys' fees if you attempt to impose such liability on Viath through legal proceedings.
			</p>

			<h2 class="pb-3 text-xl font-semibold">
				Arbitration, Disputes, and Choice of Law
			</h2>
			<p class="pb-3 prose-lg">
				<strong>
					FOR CLARITY, THIS SECTION GOVERNS ANY BUSINESS CLAIM BROUGHT BY YOU OR VIATH. ANY CLAIM NOT SUBJECT TO THIS SECTION IS INSTEAD GOVERNED BY THE TERMS OF USE.
				</strong>
			</p>

			<p class="pb-3 prose-lg">
				Except for Excluded Business Claims, any controversy or claim arising out of or relating to: (a) these Business Terms, or the breach thereof; or (b) your access to or use of your Business Account on the Site; (each such controversy or claim, a "<strong>
					Business Claim
				</strong>"), shall be settled by arbitration administered by JAMS in Colorado, and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof. The arbitrator will issue a reasoned award in writing, including all findings of fact and law upon which the award was made.
			</p>

			<p class="pb-3 prose-lg">
				"<strong>
					Excluded Business Claims
				</strong>" means Claims related to intellectual property (like copyrights and trademarks) or violations of the representations and warranties set out in these Business Terms.
			</p>

			<p class="pb-3 prose-lg">
				Business Claims shall be heard by a single arbitrator. Arbitrations will be held in Denver, Colorado, but the parties may choose for themselves whether to appear in person, by phone, or through the submission of documents. The arbitration shall be governed by the laws of the State of Colorado. The prevailing party shall be entitled to an award of reasonable attorneys' fees.
			</p>

			<p class="pb-3 prose-lg">
				NOTWITHSTANDING THE FOREGOING, FOR ANY BUSINESS CLAIM THAT IS NOT SUBJECT TO ARBITRATION, INCLUDING WITHOUT LIMITATION EXCLUDED BUSINESS CLAIMS, YOU AGREE TO SUBMIT AND CONSENT TO THE PERSONAL AND EXCLUSIVE JURISDICTION IN, AND THE EXCLUSIVE VENUE OF, THE STATE COURTS LOCATED WITHIN DENVER COUNTY, WHICH IS THE PLACE OF PERFORMANCE OF THESE BUSINESS TERMS.
			</p>

			<p class="pb-3 prose-lg">
				YOU AND VIATH AGREE THAT EACH MAY BRING OR PARTICIPATE IN BUSINESS CLAIMS AGAINST THE OTHER ONLY IN THEIR RESPECTIVE INDIVIDUAL CAPACITIES, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. UNLESS BOTH YOU AND VIATH AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN THE BUSINESS CLAIMS OF OTHER PERSONS OR PARTIES WHO MAY BE SIMILARLY SITUATED, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. IF A BUSINESS CLAIM IMPLICATES THIS SECTION, AND THIS SECTION IS FOUND TO BE INVALID, UNENFORCEABLE OR ILLEGAL BY A COURT, SUCH BUSINESS CLAIM MUST BE ADJUDICATED BY A COURT AND NOT BY AN ARBITRATOR.
			</p>
		</div>
	</DefaultLayout>
</template>

<script async>
import { APP_META } from '@/constants/index.js'
import DefaultLayout from '@/layouts/default/Index.vue'

export default {
	components: {
		DefaultLayout
	},
	data() {
		return {
			APP_META,
			meta: {
				title: `${APP_META.DOMAIN_NAME} Privacy Policy`,
				description: ' ',
				keywords: '',
				canonical: '/privacy'
			}
		}
	},
	created: function() {
		this.$store.commit('setMeta', this.meta)
	}
}
</script>
