<template>
	<div
		v-if="mission"
		class="relative z-10 grid content-center p-8 bg-white border border-gray-300 shadow-md grid-col-1 md:flex rounded-xl"
	>
		<div class="flex justify-center mb-3 md:w-1/3">
			<img
				src="@/assets/partners/leaf411/leaf411-logo.svg"
				height="103px"
				width="174px"
				alt="Leaf 411 Logo"
			>
		</div>
		<div
			class="px-4 leading-loose text-center md:text-left"
			v-html="mission.content"
		/>
	</div>
</template>

<script async>
export default {
	props: {
		mission: {
			type: Object,
			required: true
		}
	}
}
</script>
