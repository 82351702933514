<template>
	<div class="flex flex-wrap items-center">
		<div class="flex justify-center w-full md:order-2 md:w-1/2">
			<img
				class="h-64 -mt-4 md:mt-0 md:h-80"
				:class="{ isMdBreakpoint : 'md:h-full' }"
				height="414"
				width="414"
				src="@/assets/partners/leaf411/leaf411-hero.svg"
				alt="Leaf 411 Hero"
			>
		</div>

		<div class="w-full text-center md:w-1/2 md:text-left">
			<h1 class="text-2xl font-bold">
				{{ hero.title }}
			</h1>
			<p class="max-w-md py-4 mx-0 leading-relaxed">
				{{ hero.message }}
			</p>
			<a
				class="inline-block p-2 font-semibold text-white uppercase bg-green-500 rounded"
				:href="`tel:${hero.phone}`"
			>
				Call 844-LEAF411
			</a>
		</div>
	</div>
</template>

<script async>
export default {
	props: {
		hero: {
			type: Object,
			required: true
		}
	},
	computed: {
		mediaMatch () {
			return this.$store.state.mediaMatch
		},
		isMdBreakpoint () {
			return this.mediaMatch === 'md'
		}
	}
}
</script>
