<template>
	<form
		:ref="formName"
		class="text-left"
		@submit.prevent="contact"
	>
		<div
			v-for="field in inputFields"
			:key="field.name"
			class="w-full py-2"
		>
			<WwTextInput
				v-if="field.inputType === PHONE_INPUT"
				:id="field.label"
				v-model="form[field.name]"
				:name="field.label"
				:placeholder="field.placeholder"
				:input-type="TEL"
				:required="field.required"
				:label="field.label"
				:state="formPhoneInputState"
				input-class="w-full"
				@updated="handlePhoneValidation"
				@input="checkPhoneAffectsDisabledSubmit(field)"
			/>
			<WwTextInput
				v-else-if="field.inputType === TEXT_AREA"
				:id="field.label"
				v-model="form[field.name]"
				:name="field.label"
				:placeholder="field.placeholder"
				:label="field.label"
				input-class="w-full h-24"
				:input-type="TEXTAREA"
				:required="field.required"
				:state="inputFieldState(form[field.name], field.dataType)"
			/>
			<WwTextInput
				v-else
				:id="field.label"
				v-model="form[field.name]"
				:placeholder="field.placeholder"
				input-class="w-full"
				:label="field.label"
				:required="field.required"
				:input-type="field.dataType"
				:state="inputFieldState(form[field.name], field.dataType)"
			/>
		</div>

		<div class="w-full py-3">
			<button
				:disabled="isDisabled"
				type="submit"
				class="w-full p-3 font-semibold text-white uppercase rounded"
				:class="disabledButtonColor"
			>
				Send Message
			</button>
		</div>
	</form>
</template>

<script async>
import { mapMutations } from 'vuex'

import WwTextInput from '@/components/UI/WwTextInput.vue'
import { PHONE_INPUT, TEXT_AREA, TEXT_INPUT } from '@/constants/contactHub/input-types.js'
import { PHONE } from '@/constants/text-inputs/error-messages.js'
import INPUT_STATES from '@/constants/text-inputs/states.js'
import { DATE, NUMBER, TEL, TEXTAREA } from '@/constants/text-inputs/types.js'
import { ERROR } from '@/constants/toast/type.js'
import { logError } from '@/utils/error-handling.js'
import { inputFieldState } from '@/validators/text-inputs.js'

export default {
	components: {
		WwTextInput
	},
	props: {
		inputFields: {
			type: Array,
			required: true
		},
		formName: {
			type: String,
			required: true
		}
	},
	emits: [ 'show-success-message' ],
	data() {
		return {
			form: {},
			TEXT_INPUT,
			TEXT_AREA,
			PHONE_INPUT,
			phoneValid: false,
			isPhoneRequired: false,
			TEXTAREA,
			NUMBER,
			DATE,
			TEL,
			INPUT_STATES,
			PHONE,
			inputFieldState
		}
	},
	computed: {
		isDisabled() {
			return !this.hasAllRequiredData
		},
		isValidPhone() {
			return this.isPhoneRequired ? this.phoneValid : true
		},
		formData() {
			return this.form
		},
		requiredFields() {
			const fields = []
			for (let i = 0; i < this.inputFields.length; i++) {
				if (this.inputFields[i].required) {
					fields.push(this.inputFields[i].name)
				}
			}
			return fields
		},
		hasAllRequiredData() {
			for (let i = 0; i < this.requiredFields.length; i++) {
				const fieldName = this.requiredFields[i]
				if (Object.keys(this.formData).includes(fieldName) && this.hasFormData(fieldName) && this.isValidPhone) {
					continue
				} else {
					return false
				}
			}
			return true
		},
		disabledButtonColor() {
			return this.isDisabled ? 'bg-green-300 cursor-not-allowed' : 'bg-green-500'
		},
		formPhoneInputState() {
			if (!this.form?.phone?.length) {
				return INPUT_STATES.ERROR
			}
			return this.phoneValid ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		}
	},
	methods: {
		...mapMutations('toast', [ 'showToast' ]),
		hasFormData(fieldName) {
			return inputFieldState(this.formData[fieldName].value, this.formData[fieldName].inputType) === INPUT_STATES.ERROR && this.formData[fieldName] !== undefined && this.formData[fieldName] !== null
		},
		handlePhoneValidation(payload) {
			this.phoneValid = payload.isValid()
		},
		checkPhoneAffectsDisabledSubmit(field) {
			const hasPhoneInputData = !!this.formData[field.name] && !!this.formData[field.name].length
			if (field !== undefined) {
				this.isPhoneRequired = field.required ? true : hasPhoneInputData
			} else {
				this.isPhoneRequired = false
			}
		},
		async contact() {
			let tempData = {}
			for (const index in this.inputFields) {
				const field = this.inputFields[index]
				tempData = Object.assign({}, tempData, { [`${field.name}`]: this.formData[field.name] })
			}
			const postData = {
				endpoint: 'Contact/submitDynamicForm',
				params: {
					id: this.formName,
					form_fields: tempData
				},
				store: this.$store
			}
			try {
				const response = await this.$store.dispatch('newPost', postData)
				if (response?.data?.success) {
					this.$emit('show-success-message')
				}
			} catch (error) {
				this.showToast({
					primaryText: 'Review Problem',
					secondaryText: error,
					type: ERROR
				})
				logError(error)
			}
		},
		fieldState(value) {
			return value?.length ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		}
	}
}
</script>
