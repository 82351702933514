<template>
	<div class="flex flex-wrap items-center w-full pt-4">
		<img
			class="order-last w-full p-8 md:w-1/2"
			src="@/assets/partners/leafwell-hero.png"
			alt="Leafwell Hero"
			height="551px"
			width="551px"
		>
		<div class="w-full md:w-1/2">
			<div class="pb-4 text-center md:text-left">
				<h1 class="pb-4 text-2xl font-bold md:text-4xl">
					{{ title }}
				</h1>
				<p class="max-w-md pb-8 text-xl">
					{{ message }}
				</p>
				<select
					v-model="selected"
					aria-label="Select State"
					class="w-full p-2 text-lg font-semibold text-green-500 bg-transparent border-2 border-green-500 rounded appearance-none md:w-60 focus:ring focus:ring-green-300 focus:border-green-500"
				>
					<option
						disabled
						selected
						:value="null"
						class="text-black"
					>
						Select your state:
					</option>
					<option
						v-for="state in STATES"
						:key="state.value"
						:value="state.value"
						class="text-black"
					>
						{{ state.name }}
					</option>
				</select>
			</div>

			<div class="flex items-center py-4">
				<span class="pr-3 text-lg font-bold">
					powered by
				</span>
				<img
					src="@/assets/partners/leafwell-logo-black.png"
					width="128"
					height="20"
					alt="leafwell logo"
				>
			</div>
			<ul class="pl-4 text-base text-left text-gray-500 list-disc md:text-lg">
				<li
					v-for="(item, index) in bullets"
					:key="index"
					class="py-1 md:py-0"
				>
					{{ item }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script async>
import STATES from '@/constants/states.js'

export default {
	props: {
		title: {
			type: String,
			required: true
		},
		message: {
			type: String,
			required: true
		},
		bullets: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			STATES,
			selected: null
		}
	},
	watch: {
		selected() {
			this.$router.push({ name: 'leafwellState', params: { state: this.selected } })
		}
	}
}
</script>
