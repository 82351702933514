<template>
	<DefaultLayout>
		<div class="relative">
			<img
				src="@/assets/partners/leaf411/top-gradient.svg"
				alt="Page Gradient"
				class="absolute top-0 left-0 w-full"
			>
			<div class="relative max-w-6xl p-4 mx-auto">
				<hero-section :hero="copy.hero" />
				<div class="py-4">
					<mission-banner :mission="copy.mission" />
				</div>
				<div class="max-w-2xl mx-auto">
					<faq :faq="copy.faq" />
					<cta :cta="copy.cta" />
				</div>
			</div>
		</div>
	</DefaultLayout>
</template>

<script async>
import DefaultLayout from '@/layouts/default/Index.vue'

import Cta from './components/Cta.vue'
import Faq from './components/Faq.vue'
import HeroSection from './components/HeroSection.vue'
import MissionBanner from './components/MissionBanner.vue'

const PHONE_NUMBER = '+18445323411'

export default {
	components: {
		DefaultLayout,
		HeroSection,
		Faq,
		MissionBanner,
		Cta
	},
	data () {
		return {
			copy: {
				mission: {
					imgSrc: require('@/assets/partners/leaf411/leaf411-hero.svg'),
					content: 'Leaf411’s mission is to provide <span class="font-bold">education & directional support to the general public</span> about the safe use of legal cannabis. To get accurate answers from the comfort of your own home, please call (844) LEAF411 for quick & private support from a trained nurse.'
				},
				hero: {
					title: 'Got a question about cannabis consumption or specific products?',
					message: 'We’ve got you covered! Where’s Weed is partnered with Leaf411, a 501(c)(3) nonprofit operating a FREE hotline of cannabis-trained nurses, to provide immediate, individualized support for many questions relating to products, consumption, medical issues & more. Talk to a cannabis-trained nurse for free today & get answers!',
					phone: PHONE_NUMBER
				},
				faq: {
					header: 'Cannabis FAQs',
					title: 'Consumption, Products, Medical Issues & More',
					description: 'Here are a handful of common questions that Leaf411’s team of nurses will be able to help with. Please review to see if your question is the same or similar, then call (844) LEAF411 to speak to a registered nurse – at absolutely no cost to you!',
					bullets: [
						'What’s the difference between sativa & indica strain?',
						'What’s the right consumption method for me?',
						'How should I properly dose cannabis?',
						'I suffer from anxiety & depression. Which products are best for me?',
						'I have chronic pain (or another medical condition). What should I buy?'
					]
				},
				cta: {
					text: 'Call 844-Leaf411 Today!',
					hours: '(844-532-3411) — Monday-Friday <br class="md:hidden"> 8:00am-7:00pm MST <br> Saturday & Sunday by request only',
					phone: PHONE_NUMBER
				}
			}
		}
	},
	metaInfo: {
		title: 'Where\'s Weed & Leaf411 | Providing Education & Directional Support About The Safe Use of Legal Cannabis',
		meta: [ {
			name: 'description',
			content: 'Where\'s Weed and Leaf411 provide education & directional support to the general public about the safe use of legal cannabis.'
		}
		]
	}
}
</script>
